import { createClient } from "@supabase/supabase-js";
import { ImageJson, DBBlock, DBBlockExercise, DBWorkout, DBWorkoutBlock } from '@/types/ProgramTypes';

export const supabase = createClient(
  "https://cruixjfdvsldiipefzxk.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNydWl4amZkdnNsZGlpcGVmenhrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQ5NzgzOTEsImV4cCI6MjAwMDU1NDM5MX0.0dldR0P1wuQzyRxQDHsTDkr7sL_fjhAHG8-xmEOYZNM"
);

export class DBResponse {
    data: any;
    error: any;

    constructor(data: any, error: any) {
        this.data = data;
        this.error = error;
    }
}

/**
 * 
 * @param image  The image to upload
 * @param bucket  The bucket to upload to. This is usually the studio name, and is case insensitive (it will be converted to lowercase)
 * @param folder  The folder to upload to. This is case insensitive (it will be converted to lowercase) and can be null
 * @param imageName  The name of the image, without the extension. This is case insensitive (it will be converted to lowercase) and can be null as it will default to the image name
 */
export async function uploadImageAndReturnImageJson(image: File, bucket: string, folder?: string, imageName?: string, alt_string?: string, width?: number, height?: number, resize?: 'contain' | 'cover' | 'fill' , cache?: number, upsert?: boolean): Promise<DBResponse> {
    const bucketString = bucket.toLowerCase();
    const folderString = folder ? folder.toLowerCase() : null;
    const imageString = imageName ? imageName.toLowerCase() : image.name.toLowerCase().trim().replace(/\s/g, "-"); // replace spaces with dashes
    const fullPath = `${folderString}/${imageString}`;
    const cacheControl = cache?.toString() || "3600";

    try {
        let { data, error } = await supabase
            .storage
            .from(bucketString)
            .upload(fullPath, image, {
                cacheControl: cacheControl,
                upsert: true
            })
        if (error) {
            throw error
        }
        if (data) {
            const path = data.path;
            const link = supabase
            .storage
            .from(bucketString)
            .getPublicUrl(path, {
                transform: {
                    width: width? width : 300,
                    height: height? height : 500,
                    resize: resize? resize : "cover",
                },
            });
            const returnData = {
                folder: folderString,
                bucket: bucketString,
                name: imageString,
                path: path,
                url: link.data.publicUrl,
                alt: alt_string? alt_string : imageString,
            }
            return { data: returnData, error: null };
        }
    }
    catch (error) {
        console.log("Could not Upload", error);
        return { data: null, error: error };
    }
}

export function generateImageName(seed: File | string, useTime?: boolean): string {
    const imageString = seed instanceof File ? seed.name : seed;
    if (useTime) {
        return `${imageString.toLowerCase().trim().replace(/\s/g, "-")}-${Date.now()}`; // replace spaces with dashes
    }
    return imageString.toLowerCase().trim().replace(/\s/g, "-"); // replace spaces with dashes
}

/**
 * 
 * @param object - any object
 * @param setID  - if true, will set the id to a random number
 * @param delay - the delay in ms
 * @param error_chance - the chance of an error, as a percentage
 */
export async function useUploadDelaySimulator(object: any, setID?: boolean, delay?: number, error_chance?: number): Promise<DBResponse> {
  const randomDelay = delay? delay : Math.floor(Math.random() * 1000);
  const randomID = Math.floor(Math.random() * 1000);
  const randomError = error_chance? Math.random() * 100 : 0;
  const replyData = setID? {...object, id: randomID} : object;
  if (randomError < error_chance) {
      const response = new DBResponse(null, "Error");
      return new Promise((resolve) => {
          setTimeout(() => {
              resolve(response);
          }, randomDelay);
      })
  }
  else {
      const response = new DBResponse(replyData, null);
      return new Promise((resolve) => {
          setTimeout(() => {
              resolve(response);
          }, randomDelay);
      })
  }
}

export async function deleteImage(image: ImageJson): Promise<DBResponse> {
    console.log("Deleting image", image);
    const bucketString = image.bucket.toLowerCase();
    try {
        let { data, error } = await supabase
            .storage
            .from(bucketString)
            .remove([image.path])
        if (error) {
            throw error
        }
        if (data) {
            return { data: data, error: null };
        }
    }
    catch (error) {
        console.log("Could not delete image", error);
        return { data: null, error: error };
    }
}