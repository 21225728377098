<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useIconStore } from "@/stores/icon";
import ColorPicker from 'primevue/colorpicker';

const iconStore = useIconStore()

const props = defineProps({
  context: Object,
  gradients:{
    type: Boolean,
    default: false,
  },
})

const focused = ref(false)
const hovered = ref(false)
const codeInput = ref(false)
const inputValue = ref('')

const color = computed({
  get() {
    return props.context._value
  },
  set(value) {
    props.context.node.input("#" + value)
  }
});

function onCodeFocus () {
  codeInput.value = true
}

function onCodeBlur () {
  codeInput.value = false
}

watch(color, (value) => {
  inputValue.value = value
})
function onShow() {
  focused.value = true
}
function onHide() {
  focused.value = false
}

const bgVisible = computed(() => {
  return focused.value || hovered.value
})

const gradientsVisible = computed(() => {
  if (props.gradients) {
    console.log("gradients")
    return true
  } else if (props.context.attrs.props != undefined && props.context.attrs.props.gradients != undefined && props.context.attrs.props.gradients) {
    console.log("FormKit gradients")
    return true
  } else {
    console.log("no gradients")
    return false
  }
})



const resultText = computed(() => {
  if (themeOptions.value.filter((option) => option.value == inputValue.value).length > 0) {
    return themeOptions.value.filter((option) => option.value == inputValue.value)[0].label
  } else if (options.value.filter((option) => option.value == inputValue.value).length > 0) {
    return options.value.filter((option) => option.value == inputValue.value)[0].label
  } else if (gradientOptions.value.filter((option) => option.value == inputValue.value).length > 0) {
    return gradientOptions.value.filter((option) => option.value == inputValue.value)[0].label
  } else if (props.context._value == null || props.context._value == "") {
    return "#ffffff"
  } else {
    return inputValue.value
  }
})

const handleInput = computed({
  get() {
    return resultText.value
  },
  set(value) {
    props.context.node.input(value)
    inputValue.value = value
  }
});

const resultColor = computed (() => {
   if (props.context._value == null || props.context._value == "") {
    return "#ffffff"
  } else {
    return inputValue.value
  }
})


const options = ref([
  { label: "Blue 700", value: "var(--blue-700)" },
  { label: "Blue 500", value: "var(--blue-500)" },
  { label: "Blue 300", value: "var(--blue-300)" },
  { label: "Light blue 700", value: "var(--lightblue-700)" },
  { label: "Light blue 500", value: "var(--lightblue-500)" },
  { label: "Light blue 300", value: "var(--lightblue-300)" },
  { label: "Cyan 700", value: "var(--cyan-700)" },
  { label: "Cyan 500", value: "var(--cyan-500)" },
  { label: "Cyan 300", value: "var(--cyan-300)" },
  { label: "Teal 700", value: "var(--teal-700)" },
  { label: "Teal 500", value: "var(--teal-500)" },
  { label: "Teal 300", value: "var(--teal-300)" },
  { label: "Green 700", value: "var(--green-700)" },
  { label: "Green 500", value: "var(--green-500)" },
  { label: "Green 300", value: "var(--green-300)" },
  { label: "Light green 700", value: "var(--lightgreen-700)" },
  { label: "Light green 500", value: "var(--lightgreen-500)" },
  { label: "Light green 300", value: "var(--lightgreen-300)" },
  { label: "Yellow 700", value: "var(--yellow-700)" },
  { label: "Yellow 500", value: "var(--yellow-500)" },
  { label: "Yellow 300", value: "var(--yellow-300)" },
  { label: "Orange 700", value: "var(--orange-700)" },
  { label: "Orange 500", value: "var(--orange-500)" },
  { label: "Orange 300", value: "var(--orange-300)" },
  { label: "Red 700", value: "var(--red-700)" },
  { label: "Red 500", value: "var(--red-500)" },
  { label: "Red 300", value: "var(--red-300)" },
  { label: "Pink 700", value: "var(--pink-700)" },
  { label: "Pink 500", value: "var(--pink-500)" },
  { label: "Pink 300", value: "var(--pink-300)" },
  ]
)

const themeOptions = ref([
  { label: "Default", value: "var(--grey-dark)" },
  { label: "Primary", value: "var(--primary)" },
  { label: "Secondary", value: "var(--secondary)" },
  { label: "Success", value: "var(--success)" },
  { label: "Danger", value: "var(--danger)" },
  { label: "Warning", value: "var(--warn)" },
  ],
)

const gradientOptions = ref([
  { label: "Gradient 1", value: "var(--gradients-gradientstyle1)" },
  { label: "Gradient 2", value: "var(--gradients-gradientstyle2)" },
  { label: "Gradient 3", value: "var(--gradients-gradientstyle3)" },
  { label: "Gradient 4", value: "var(--gradients-gradientstyle4)" },
  { label: "Gradient 5", value: "var(--gradients-gradientstyle5)" },
  { label: "Gradient 6", value: "var(--gradients-gradientstyle6)" },
  { label: "Gradient 7", value: "var(--gradients-gradientstyle7)" },
  { label: "Gradient 8", value: "var(--gradients-gradientstyle8)" },
  { label: "Gradient 9", value: "var(--gradients-gradientstyle9)" },
  { label: "Gradient 10", value: "var(--gradients-gradientstyle10)" },
  { label: "Gradient 11", value: "var(--gradients-gradientstyle11)" },
  { label: "Gradient 12", value: "var(--gradients-gradientstyle12)" },
  { label: "Gradient 13", value: "var(--gradients-gradientstyle13)" },
  { label: "Gradient 14", value: "var(--gradients-gradientstyle14)" },
  { label: "Gradient 15", value: "var(--gradients-gradientstyle15)" },
  { label: "Gradient 16", value: "var(--gradients-gradientstyle16)" },
  { label: "Gradient 17", value: "var(--gradients-gradientstyle17)" },
  { label: "Gradient 18", value: "var(--gradients-gradientstyle18)" },
  { label: "Gradient 19", value: "var(--gradients-gradientstyle19)" },
  { label: "Gradient 20", value: "var(--gradients-gradientstyle20)" },
  { label: "Gradient 21", value: "var(--gradients-gradientstyle21)" },
  { label: "Gradient 22", value: "var(--gradients-gradientstyle22)" },
  { label: "Gradient 23", value: "var(--gradients-gradientstyle23)" },
  { label: "Gradient 24", value: "var(--gradients-gradientstyle24)" },
  { label: "Gradient 25", value: "var(--gradients-gradientstyle25)" },
  { label: "Gradient 26", value: "var(--gradients-specialgradient-style26)" },
  { label: "Gradient 27", value: "var(--gradients-specialgradient-style27)" },
  { label: "Gradient 28", value: "var(--gradients-specialgradient-style28)" },
  { label: "Monochrome 1", value: "var(--monochromegradients-monochromegradient1)" },
  { label: "Monochrome 2", value: "var(--monochromegradients-monochromegradient2)" },
  ],
)

function onColorClick(color) {
  props.context.node.input(color)
  inputValue.value = color
}

onMounted(() => {
  inputValue.value = props.context._value
})
</script>

<template>
<div @mouseenter="hovered = true" @mouseleave="hovered = false">
  <div class="pickerWrapper">
    <div class="resultWrapper">
      <div class="result" :style="{background: resultColor}"></div>
      <input class="resultTextDisplay" :value="resultText"/>
    </div>
    <input type="text" v-model="inputValue" class="input" :class="{focused: focused, hovered: hovered}" />
  </div>
  <TransitionGroup name="picker">
    <div class="background" v-if="bgVisible">
      <div class="title-group">
        <p>Color Picker</p>
        <div class="i-linear-brush"></div>
      </div>
      <div class="resultWrapper lift">
        <div class="result" :style="{background: resultColor}"></div>
        <input v-if="bgVisible" type="text" class="resultText" v-model="handleInput" @focus="onCodeFocus()" @blur="onCodeBlur()"/>
      </div>
      <ColorPicker v-model="color" @show="onShow()" @hide="onHide()" class="colorPicker2" :inline="true"/>
      <div class="spacer" >
        <perfect-scrollbar>
          <div class="subtitle" >Theme Colors</div>
          <div class="popular">
                  <div class="colorSample" v-for="option in themeOptions" :key="option.label" :style="{backgroundColor: option.value}" @click="onColorClick(option.value)">
              </div>
          </div>
          <div class="subtitle" >Named Colors</div>
          <div class="popular ">
                  <div class="colorSample" v-for="option in options" :key="option.label" :style="{backgroundColor: option.value}" @click="onColorClick(option.value)">
              </div>
          </div>
          <div class="subtitle" v-if="gradientsVisible">Color Gradients</div>
          <div class="popular" v-if="gradientsVisible">
                  <div class="colorSample" v-for="option in gradientOptions" :key="option.label" :style="{background: option.value}" @click="onColorClick(option.value)">
              </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </TransitionGroup>
</div>
</template>

<style scoped lang="scss">
.ps {
  display: block;
  height: 100%;
  width: 100%;
  overflow: visible;
  position: relative;
  left: -1rem;
  top: 0;
  width: calc(100% + 2rem);
  height: 100%;
  max-height: 290px;
  padding: 0rem 1rem 1rem 1rem;
}
.result {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: var(--radius);
  background-color: var(--primary);
  top: 0;
  left: 0;
  box-shadow: 0 2px 0.75rem -2px #0000007c;
}
.resultText {
  color: var(--text);
  font-size: 1rem;
  white-space: nowrap;
  outline: none;
  border: none;
  background-color: transparent;
  width: 8rem;
}
.resultTextDisplay {
  color: var(--text);
  font-size: 1rem;
  white-space: nowrap;
  width: 8rem;
  white-space: nowrap;
  outline: none;
  border: none;
  background-color: transparent;
}
.resultWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: var(--radius);
  box-shadow: 0 0 0 1 #ffffff00;
  &.lift {
    box-shadow: var(--fk-border-box-shadow-focus);
    margin-top: 1rem;
  }
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  // background-color: transparent;
  color: var(--text);
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: var(--radius);
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.inputCode {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // z-index: 1000;
  color: var(--text);
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: var(--radius);
  transition: all 0.2s ease-in-out;
  opacity: 1;
  background-color: var(--grey);
}

.colorSample {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: var(--primary);
  box-shadow: 0 2px 0.75rem -3px var(--grey-dark);
  cursor: pointer;
  transition: var(--transition-fast);
  border: 1px solid rgba(255, 255, 255, 1);
  &:hover {
    box-shadow: 0 2px 0.75rem -3px var(--dark-grey);
    transform: scale(1.2);
    transform-origin: bottom;
    border: 1px solid rgba(255, 255, 255, 1);
  }
}
.pickerWrapper {
  width: 100%;
  min-width: 200px;
  height: 100%;
  position: relative;
}
.background {
    display: flex;
    flex-direction: column;
    z-index: 90;
    position: absolute;
    top: -3rem;
    left: -1rem;
    padding: 1rem 1rem 0 1rem;
    width: calc(100% + 2rem);
    min-width: min-content;
    min-height: min-content;
    background-color: var(--bg);
    border-radius: var(--radius-xl);
    box-shadow: var(--shadow);
}
  .title-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 1rem;
    p {
        font: var(--h6);
        color: var(--grey);
        line-height: 1.5rem;
    }
    .i-linear-brush {
        font-size: 1.5rem;
        color: var(--grey);
    }
}
.spacer {
    padding-top: 0rem;
}
.subtitle{
    font: var(--h6);
    color: var(--grey);
    line-height: 2.5rem;
}

.popular {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    padding-top: 0;
}
.picker-enter-active {
  transition: var(--transition-fast);
}
.picker-leave-active {
  transition: var(--transition-fast);
}
.picker-enter-from,
.picker-leave-to {
  opacity: 0;
}
</style>


<style lang="scss">
.p-colorpicker-content {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.p-colorpicker-panel {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.p-colorpicker-color {
  width: 100% !important;
  height: 100% !important;
  border-radius: var(--radius);
}
.p-colorpicker-color-selector {
  border-radius: var(--radius);
  box-shadow: var(--shadow);
}


.p-colorpicker {
    display: inline-block;
}

.p-colorpicker-dragging {
    cursor: pointer;
}

.p-colorpicker-overlay {
    position: relative;
}

.p-colorpicker-panel {
    position: relative;
    width: 193px;
    height: 166px;
}

.p-colorpicker-overlay-panel {
    position: absolute;
    top: 0;
    left: 0;
}

.p-colorpicker-preview {
    cursor: pointer;
    width: 100% !important;
    height: 100% !important;
    border-radius: var(--radius);
}

.p-colorpicker-panel .p-colorpicker-content {
    position: relative;
}

.p-colorpicker-panel .p-colorpicker-color-selector {
    width: 150px;
    height: 150px;
    top: 1rem;
    left: 0.25rem;
    position: absolute;
}

.p-colorpicker-panel .p-colorpicker-color {
    width: 150px;
    height: 150px;
}

.p-colorpicker-panel .p-colorpicker-color-handle {
    position: absolute;
    top: 0px;
    left: 150px;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    border-width: 1px;
    border-style: solid;
    margin: -5px 0 0 -5px;
    cursor: pointer;
    opacity: 0.85;
}
.p-colorpicker-hue {
}

.p-colorpicker-panel .p-colorpicker-hue {
    width: 26px;
    height: 150px;
    top: 1rem;
    left: 180px;
    position: absolute;
    opacity: 0.85;
}

.p-colorpicker-panel .p-colorpicker-hue-handle {
    position: absolute;
    top: 150px;
    left: 0px;
    width: 29px;
    margin-left: -5px;
    margin-top: -5px;
    height: 10px;
    border-width: 3px;
    border-style: solid;
    border-color: var(--dark-grey);
    opacity: 1;
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    cursor: pointer;
}

</style>