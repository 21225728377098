import _definePage_default_0 from '/vercel/path0/src/pages/[studio]/index.vue?definePage&vue'
import { _mergeRouteRecord, _HasDataLoaderMeta } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/vercel/path0/src/pages/[studio]/manage.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/vercel/path0/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:studio',
    name: '/[studio]',
    component: () => import('/vercel/path0/src/pages/[studio].vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/[studio]/',
        component: () => import('/vercel/path0/src/pages/[studio]/index.vue'),
        /* no children */
      },
  _definePage_default_0
  ),
      {
        path: 'home',
        name: '/[studio]/home',
        component: () => import('/vercel/path0/src/pages/[studio]/home.vue'),
        children: [
          {
            path: '',
            name: '/[studio]/home/',
            component: () => import('/vercel/path0/src/pages/[studio]/home/index.vue'),
            /* no children */
          },
          {
            path: 'encyclopedia',
            name: '/[studio]/home/encyclopedia',
            component: () => import('/vercel/path0/src/pages/[studio]/home/encyclopedia.vue'),
            children: [
              {
                path: '',
                name: '/[studio]/home/encyclopedia/',
                component: () => import('/vercel/path0/src/pages/[studio]/home/encyclopedia/index.vue'),
                /* no children */
              },
              {
                path: ':exerciseid',
                name: '/[studio]/home/encyclopedia/[exerciseid]',
                component: () => import('/vercel/path0/src/pages/[studio]/home/encyclopedia/[exerciseid].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'programs',
            name: '/[studio]/home/programs',
            component: () => import('/vercel/path0/src/pages/[studio]/home/programs.vue'),
            children: [
              {
                path: '',
                name: '/[studio]/home/programs/',
                component: () => import('/vercel/path0/src/pages/[studio]/home/programs/index.vue'),
                /* no children */
              },
              {
                path: ':classid',
                name: '/[studio]/home/programs/[classid]',
                component: () => import('/vercel/path0/src/pages/[studio]/home/programs/[classid].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'timetable',
            name: '/[studio]/home/timetable',
            component: () => import('/vercel/path0/src/pages/[studio]/home/timetable.vue'),
            /* no children */
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'manage',
        name: '/[studio]/manage',
        component: () => import('/vercel/path0/src/pages/[studio]/manage.vue'),
        children: [
          {
            path: 'members',
            /* internal name: '/[studio]/manage/members' */
            /* no component */
            children: [
              {
                path: '',
                name: '/[studio]/manage/members/',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/members/index.vue'),
                /* no children */
              },
              {
                path: 'leads',
                name: '/[studio]/manage/members/leads',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/members/leads.vue'),
                /* no children */
              },
              {
                path: 'members',
                name: '/[studio]/manage/members/members',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/members/members.vue'),
                /* no children */
              },
              {
                path: 'past-members',
                name: '/[studio]/manage/members/past-members',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/members/past-members.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'sales',
            /* internal name: '/[studio]/manage/sales' */
            /* no component */
            children: [
              {
                path: '',
                name: '/[studio]/manage/sales/',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/index.vue'),
                /* no children */
              },
              {
                path: 'funnels',
                name: '/[studio]/manage/sales/funnels',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/funnels.vue'),
                /* no children */
              },
              {
                path: 'Insights',
                name: '/[studio]/manage/sales/Insights',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/Insights.vue'),
                /* no children */
              },
              {
                path: 'Invoices',
                name: '/[studio]/manage/sales/Invoices',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/Invoices.vue'),
                /* no children */
              },
              {
                path: 'marketing',
                name: '/[studio]/manage/sales/marketing',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/marketing.vue'),
                /* no children */
              },
              {
                path: 'memberships',
                name: '/[studio]/manage/sales/memberships',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/memberships.vue'),
                /* no children */
              },
              {
                path: 'packages',
                name: '/[studio]/manage/sales/packages',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/packages.vue'),
                /* no children */
              },
              {
                path: 'products',
                name: '/[studio]/manage/sales/products',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/products.vue'),
                /* no children */
              },
              {
                path: 'retention',
                name: '/[studio]/manage/sales/retention',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/retention.vue'),
                /* no children */
              },
              {
                path: 'targets',
                name: '/[studio]/manage/sales/targets',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/sales/targets.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'schedule',
            /* internal name: '/[studio]/manage/schedule' */
            /* no component */
            children: [
              {
                path: '',
                name: '/[studio]/manage/schedule/',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/schedule/index.vue'),
                /* no children */
              },
              {
                path: 'appointments',
                name: '/[studio]/manage/schedule/appointments',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/schedule/appointments.vue'),
                /* no children */
              },
              {
                path: 'classes',
                name: '/[studio]/manage/schedule/classes',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/schedule/classes.vue'),
                /* no children */
              },
              {
                path: 'courses',
                name: '/[studio]/manage/schedule/courses',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/schedule/courses.vue'),
                /* no children */
              },
              {
                path: 'events',
                name: '/[studio]/manage/schedule/events',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/schedule/events.vue'),
                /* no children */
              },
              {
                path: 'policies',
                name: '/[studio]/manage/schedule/policies',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/schedule/policies.vue'),
                /* no children */
              },
              {
                path: 'workshops',
                name: '/[studio]/manage/schedule/workshops',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/schedule/workshops.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'settings',
            /* internal name: '/[studio]/manage/settings' */
            /* no component */
            children: [
              {
                path: '',
                name: '/[studio]/manage/settings/',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/settings/index.vue'),
                /* no children */
              },
              {
                path: 'app',
                name: '/[studio]/manage/settings/app',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/settings/app.vue'),
                /* no children */
              },
              {
                path: 'banking',
                name: '/[studio]/manage/settings/banking',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/settings/banking.vue'),
                /* no children */
              },
              {
                path: 'lead-capture',
                name: '/[studio]/manage/settings/lead-capture',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/settings/lead-capture.vue'),
                /* no children */
              },
              {
                path: 'portal',
                name: '/[studio]/manage/settings/portal',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/settings/portal.vue'),
                /* no children */
              },
              {
                path: 'your-plan',
                name: '/[studio]/manage/settings/your-plan',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/settings/your-plan.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'Studio',
            /* internal name: '/[studio]/manage/Studio' */
            /* no component */
            children: [
              {
                path: '',
                name: '/[studio]/manage/Studio/',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/index.vue'),
                /* no children */
              },
              {
                path: 'automation',
                name: '/[studio]/manage/Studio/automation',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/automation.vue'),
                /* no children */
              },
              {
                path: 'facilities',
                name: '/[studio]/manage/Studio/facilities',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/facilities.vue'),
                /* no children */
              },
              {
                path: 'files',
                name: '/[studio]/manage/Studio/files',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/files.vue'),
                /* no children */
              },
              {
                path: 'messenger',
                name: '/[studio]/manage/Studio/messenger',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/messenger.vue'),
                /* no children */
              },
              {
                path: 'reception',
                name: '/[studio]/manage/Studio/reception',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/reception.vue'),
                /* no children */
              },
              {
                path: 'reports',
                name: '/[studio]/manage/Studio/reports',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/reports.vue'),
                /* no children */
              },
              {
                path: 'staff',
                name: '/[studio]/manage/Studio/staff',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/Studio/staff.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'training',
            /* internal name: '/[studio]/manage/training' */
            /* no component */
            children: [
              {
                path: '',
                name: '/[studio]/manage/training/',
                component: () => import('/vercel/path0/src/pages/[studio]/manage/training/index.vue'),
                /* no children */
              },
              {
                path: 'coaching',
                /* internal name: '/[studio]/manage/training/coaching' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/coaching/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/coaching/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'exercises',
                /* internal name: '/[studio]/manage/training/exercises' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/exercises/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/exercises/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'flows',
                /* internal name: '/[studio]/manage/training/flows' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/flows/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/flows/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':flowID',
                    name: '/[studio]/manage/training/flows/[flowID]',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/flows/[flowID].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'grading',
                /* internal name: '/[studio]/manage/training/grading' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/grading/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/grading/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'levels',
                /* internal name: '/[studio]/manage/training/levels' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/levels/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/levels/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'programs',
                /* internal name: '/[studio]/manage/training/programs' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/programs/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/programs/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':progid',
                    /* internal name: '/[studio]/manage/training/programs/[progid]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/[studio]/manage/training/programs/[progid]/',
                        component: () => import('/vercel/path0/src/pages/[studio]/manage/training/programs/[progid]/index.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'Skilltree',
                /* internal name: '/[studio]/manage/training/Skilltree' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/Skilltree/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/Skilltree/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'workouts',
                /* internal name: '/[studio]/manage/training/workouts' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/[studio]/manage/training/workouts/',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/workouts/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':workoutID',
                    name: '/[studio]/manage/training/workouts/[workoutID]',
                    component: () => import('/vercel/path0/src/pages/[studio]/manage/training/workouts/[workoutID].vue'),
                    /* no children */
                  }
                ],
              }
            ],
          }
        ],
      },
  _definePage_default_2
  ),
      {
        path: 'portal',
        name: '/[studio]/portal',
        component: () => import('/vercel/path0/src/pages/[studio]/portal.vue'),
        /* no children */
      },
      {
        path: 'workout',
        /* internal name: '/[studio]/workout' */
        /* no component */
        children: [
          {
            path: '',
            name: '/[studio]/workout/',
            component: () => import('/vercel/path0/src/pages/[studio]/workout/index.vue'),
            /* no children */
          },
          {
            path: ':workoutid',
            /* internal name: '/[studio]/workout/[workoutid]' */
            /* no component */
            children: [
              {
                path: '',
                name: '/[studio]/workout/[workoutid]/',
                component: () => import('/vercel/path0/src/pages/[studio]/workout/[workoutid]/index.vue'),
                /* no children */
              },
              {
                path: 'active',
                name: '/[studio]/workout/[workoutid]/active',
                component: () => import('/vercel/path0/src/pages/[studio]/workout/[workoutid]/active.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/classes',
    /* internal name: '/classes' */
    /* no component */
    children: [
      {
        path: ':class',
        /* internal name: '/classes/[class]' */
        /* no component */
        children: [
          {
            path: 'Index',
            name: '/classes/[class]/Index',
            component: () => import('/vercel/path0/src/pages/classes/[class]/Index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'Index',
        name: '/classes/Index',
        component: () => import('/vercel/path0/src/pages/classes/Index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/reset',
    name: '/reset',
    component: () => import('/vercel/path0/src/pages/reset.vue'),
    /* no children */
  },
  {
    path: '/welcome',
    /* internal name: '/welcome' */
    /* no component */
    children: [
      {
        path: '',
        name: '/welcome/',
        component: () => import('/vercel/path0/src/pages/welcome/index.vue'),
        /* no children */
      },
      {
        path: 'setup',
        /* internal name: '/welcome/setup' */
        /* no component */
        children: [
          {
            path: '',
            name: '/welcome/setup/',
            component: () => import('/vercel/path0/src/pages/welcome/setup/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]
