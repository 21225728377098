import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { supabase } from "@/lib/supabaseClient";
import { useToast } from 'vue-toastification';

const toast = useToast();

export const useAppStore = defineStore("app", () => {
  const authUser = ref(null);
  const userData = ref(null);
  const studio = ref(null);

  const mobileMenuOpen = ref(false);

  const activeView = ref("programs");
  const previousView = ref(null);

  const activeClass = ref(null);
  const previousClass = ref(null);

  const activeWorkout = ref(null);

  const workspaces = ref([
    {
      title: "Home",
      routeLink: "home",
      icon: "i-linear-home",
    },
    {
      title: "Programs",
      routeLink: "programs",
      icon: "i-linear-clipboard",
    },
    {
      title: "Timetable",
      routeLink: "timetable",
      icon: "i-linear-calendar",
    },
    {
      title: "Encyclopedia",
      routeLink: "encyclopedia",
      icon: "i-linear-book",
    },
  ]);

  const studentsPlaceholder = ref([
    {
      id: 1,
      name: "Student 1",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 2,
      name: "Student 2",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 3,
      name: "Student 3",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 4,
      name: "Student 4",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 5,
      name: "Student 5",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 6,
      name: "Student 6",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 7,
      name: "Student 7",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 8,
      name: "Student 8",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 9,
      name: "Student 9",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 10,
      name: "Student 10",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 11,
      name: "Student 11",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
    {
      id: 12,
      name: "Student 12",
      photo: "https://cruixjfdvsldiipefzxk.supabase.co/storage/v1/object/public/Images/teagan.jpg",
    },
  ]);

  function setAuthUser(user) {
    authUser.value = user;
  }
  function resetStore() {
    authUser.value = null;
    activeView.value = "Programs";
    activeClass.value = null;
    activeWorkout.value = null;
    previousView.value = null;
    previousClass.value = null;
    userData.value = null;
    studio.value = null;
  }

  function setActiveView(view) {
    activeView.value = view;
  }

  function setActiveClass(classId) {
    activeClass.value = classId;
  }

  function setWorkout(workoutId) {
    activeWorkout.value = workoutId;
  }

  function openMenu() {
    mobileMenuOpen.value = true;
  }

  function closeMenu() {
    mobileMenuOpen.value = false;
  }

  async function signout() {
    const { error } = await supabase.auth.signOut();
    resetStore();
    toast.success("You have been signed out");
  }

 async function getUserData(uid) {
    try {
      let { data, error } = await supabase
        .from("users")
        .select("*, role:role (*)")
        .eq("auth_id", uid)
        .single();
      if (error) throw error;
      if (data) {
        if (data.setup_complete === false) {
          userData.value = data;
          return "setup not complete";
        } else {
        userData.value = data;
        await getStudioData(data.studio_id);
        return "setup complete";
        }
      }
    } catch (error) {
      console.log("No user data found in database", error);
      console.log(uid)
      return "error";
    }
  }

  async function getStudioData(studioID) {
    try {
      let { data, error } = await supabase
        .from("studios")
        .select("*")
        .eq("id", studioID)
        .single();
      if (error) throw error;
      if (data) {
        studio.value = data;
      }
    } catch (error) {
      console.log("No studio data found in database", error);
      return false;
    }
  }

  function goBack() {
    if (activeWorkout.value) {
      activeWorkout.value = null;
      setActiveView(previousView.value);
      setActiveClass(previousClass.value);
      previousView.value = null;
      previousClass.value = null;
      return;
  }
  if (activeClass.value) {
      setActiveView("Classes");
      setActiveClass(null);
      return;
  }
  setActiveView("Classes");
  setActiveClass(null);
  }
    
return {
    authUser,
    userData,
    workspaces,
    mobileMenuOpen,
    activeView,
    activeClass,
    activeWorkout,
    previousView,
    previousClass,
    studentsPlaceholder,
    studio,
    setAuthUser,
    getStudioData,
    getUserData,
    setActiveView,
    setActiveClass,
    setWorkout,
    goBack,
    openMenu,
    closeMenu,
    signout,
    resetStore,
};
});