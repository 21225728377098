<script setup lang="ts">
import { ref, onMounted, computed, watch, getCurrentInstance } from "vue";
import { ImageJson } from "@/types/ProgramTypes";
import { supabase, deleteImage } from "@/lib/supabaseClient";
import { useToast } from "vue-toastification";

const toast = useToast();
const instance = getCurrentInstance();

const props = defineProps({
  context: Object,
  bucket: {
    type: String,
    default: 'Images',
  },
})

const loading = ref(false);
const error = ref(false)
const image = ref(props.context.node.value);
const imageData = ref(null); 
const imagePath = ref("No image selected");



watch(
  () => props.context.value,
  (newVal, oldVal) => {
    if (newVal) {
      if (newVal instanceof File) {
        // image.value = URL.createObjectURL(newVal);
        // imagePath.value = newVal.name;
      }
      else if (typeof newVal === 'string' && newVal != "") {
        image.value = newVal;
        imagePath.value = newVal;
      }
      else if (typeof(newVal) == 'object' && newVal != null && newVal.name != undefined && newVal.url != undefined) { // ImageJson
        image.value = newVal.url;
        imageData.value = newVal;
        imagePath.value = newVal.name;
      }
      else {
        image.value = null;
        imagePath.value = "No image selected";
      }
    }
  },
  { deep: true, immediate: true }
);

// watch(
//   () => props.context.attrs,
//   () => {
//     imageData.value = props.context.attrs.imageData? props.context.attrs.imageData : null;
//     imagePath.value = props.context.attrs.imageData? props.context.attrs.imageData?.name : "No image selected";
//   },
//   { deep: true }
// );

const imageSrc = computed(() => {
  if (image.value != null && image.value != undefined) {
    return image.value;
  } else if (imageData.value != null && imageData.value.url != undefined) {
    return imageData.value.url;
  } else {
    return null;
  }
})

const imageIndicatorLeft = computed(() => {
  if (imagePath.value != null || imagePath.value != undefined) {
    return 'i-linear-tick-circle green';
  } else if (error.value) {
    return 'i-linear-close-circle red';
  }
  else {
    return 'i-linear-image';
  }
})

const imageIndicatorRight = computed(() => {
  if (imagePath.value != "No image selected" && imagePath.value != null && imagePath.value != undefined) {
    return 'i-linear-trash red';
  }
  else if (error.value) {
    return 'i-linear-close-circle red';
  }
  else {
    return 'i-linear-image';
  }
})

async function deleteHandler() {
  if (imagePath.value != "No image selected" && imagePath.value != null && imagePath.value != undefined) {
    console.log('deleting image', imageData.value, imagePath.value, image.value);
    // const result = await deleteImage(imageData.value);
    // if (result.error) {
    //   error.value = true;
    // } else {
    //   props.context.node.input(null);
    //   image.value = null;
    //   imageData.value = null;
    //   imagePath.value = "No image selected";
    //   error.value = false;
    //   toast.success("Image deleted successfully");
    // }
  } else {
    return;
  }
}

props.context.node.on('prop', ({ payload }) => {
  if (typeof(payload.value) == 'object' && payload != null && payload.name != undefined && payload.url != undefined) { // ImageJson
    image.value = payload.url;
    imageData.value = payload;
    imagePath.value = payload.name;
  } else if (typeof(payload) == 'string' && payload != "") {
    image.value = payload;
    imagePath.value = payload;
  }
});

function handleChange(e) {
  if (e.target.files.length > 0) {
    const file = e.target.files[0];
    props.context.node.input(file);
    image.value = URL.createObjectURL(file);
    imagePath.value = file.name;
  } else {
    props.context.node.input(null);
    image.value = null;
    imagePath.value = "No image selected";
  }
}

</script>


<template>
<div class="container" :class="context.classes.filePicker"> 
  <!-- <div class="title">
    <div>Select an Image</div>
    <div class="i-linear-search-normal"></div>
  </div> -->
  <div class="image-wrapper" v-if="imageSrc != null" >
    <img :src="imageSrc" />
    <input class="filepicker-input" type="file" @change="(e) => handleChange(e)"> 
  </div>
  <div class="loading-wrapper" v-else-if="loading" >
    <base-loading-spinner />
  </div>
  <div class="header" v-else> 
      <div class="icon large i-linear-gallery-add"></div>
      <div>Select an image</div>
      <input class="filepicker-input" type="file" @change="(e) => handleChange(e)"> 
  </div> 
  <label for="file" class="footer"> 
    <div class="icon" :class="imageIndicatorLeft"></div>
    <p>{{ imagePath }}</p> 
    <div class="icon" :class="imageIndicatorRight" @click="deleteHandler"></div>
  </label> 
</div>
</template>

<style scoped lang="scss">
.loading-wrapper {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-wrapper {
  width: 100% !important;
  height: 100% !important;
  border-radius: var(--radius);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey);
  box-shadow: var(--shadow);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.filepicker-input {
  display: flex;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  background-color: white;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
}
.container {
  min-height: 300px;
  height: 100%;
  width: 100%;
  // border-radius: var(--radius-lg) !important;
  // box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--pad);
  gap: var(--gap-sm);
  // background-color: var(--bg);
}
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-sm);
  flex-direction: row;
  color: var(--color-text);
}
.header {
  flex: 1;
  width: 100%;
  border: 2px dashed var(--grey);
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--gap-sm);
  cursor: pointer;
  background: var(--bg);
  box-shadow: var(--shadow);
}

.icon {
  font-size: 1.5rem;
  color: var(--grey);
  &.i-linear-trash {
    cursor: pointer;
    transition: var(--transition);
    &:hover {
      transform: scale(1.1);
    }
  }
  &.green {
    color: var(--success);
  }
  &.red {
    color: var(--error);
  }
}

.header p {
  text-align: center;
  color: black;
}

.footer {
  background-color: var(--bg);
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  // cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: black;
  border: none;
  box-shadow: var(--shadow-short);
  transition: var(--transition);
  &:hover {
    box-shadow: var(--shadow-hover);
    transform: var(--transform-hover);
  }
  p {
    flex: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px 8px;
  }
}

.footer svg {
  height: 130%;
  fill: royalblue;
  background-color: rgba(70, 66, 66, 0.103);
  border-radius: 50%;
  padding: 2px;
  // cursor: pointer;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.205);
}

.footer p {
  flex: 1;
  text-align: center;
}

#file {
  display: none;
} 
</style>