import { defaultConfig } from "@formkit/vue";
import { createFloatingLabelsPlugin } from "@formkit/addons";
import { createAutoHeightTextareaPlugin } from '@formkit/addons'
// import "@formkit/addons/css/floatingLabels";
import "uno.css";
import { createProPlugin, inputs } from "@formkit/pro";
import InputsIconPicker from '@/components/Inputs/IconPicker.vue'
import InputsColorPicker from '@/components/Inputs/ColorPicker.vue'
// import InputsSocialbar from '@/components/Inputs/Socialbar.vue'
import InputsFilePicker from '@/components/Inputs/FilePicker.vue'
import { createInput } from '@formkit/vue'

const iconPicker = createInput(InputsIconPicker)
const colorPicker = createInput(InputsColorPicker)
// const socialBar = createInput(InputsSocialbar)
const filePicker = createInput(InputsFilePicker)


// const iconPicker = {
//   type: 'input',
//   component: BaseIconPicker,
//   props: {
//     // context: '$node.context'
//   },
// }

const pro = createProPlugin("fk-3de4d30303", inputs);
const config = defaultConfig({
  plugins: [
    createFloatingLabelsPlugin({
      useAsDefault: false, // defaults to false
    }),
    createAutoHeightTextareaPlugin(),
    pro,
  ],
  inputs: {
    iconPicker: iconPicker,
    colorPicker: colorPicker,
    // socialBar: socialBar,
    filePicker: filePicker,
  },
});

export default config;
