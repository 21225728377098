<script setup>
import { onBeforeMount, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router/auto";
import { definePage } from 'vue-router/auto'

definePage({
  meta: {
    requiresAuth: true,
  },
})

const router = useRouter();
const route = useRoute();
const studio = route.params.studio;

onMounted(() => {
    router.push("/" + studio + "/home");
});
</script>

<template>
    <div>
        Redirecting...
    </div>
</template>