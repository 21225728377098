import { defineStore } from "pinia";
import { supabase } from "@/lib/supabaseClient";

export const useIconStore = defineStore("Icon", {
  state: () => ({
    popular: [
      {
        label: "heart",
        value: "i-linear-heart",
      },
      {
        label: "card-send",
        value: "i-linear-card-send",
      },
      {
        label: "trash",
        value: "i-linear-trash",
      },
      {
        label: "verify",
        value: "i-linear-verify",
      },
      {
        label: "crown",
        value: "i-linear-crown",
      },
      {
        label: "home",
        value: "i-linear-home",
      },
      {
        label: "camera",
        value: "i-linear-camera",
      },
      {
        label: "gallery",
        value: "i-linear-gallery",
      },
      {
        label: "music",
        value: "i-linear-music",
      },
      {
        label: "dollar-square",
        value: "i-linear-dollar-square",
      },
      {
        label: "shop",
        value: "i-linear-shop",
      },
      {
        label: "ticket",
        value: "i-linear-ticket",
      },
      {
        label: "like",
        value: "i-linear-like",
      },
      {
        label: "medal-star",
        value: "i-linear-medal-star",
      },
      {
        label: "calendar",
        value: "i-linear-calendar",
      },
      {
        label: "lock",
        value: "i-linear-lock",
      },
      {
        label: "message",
        value: "i-linear-message",
      },
      {
        label: "calendar-edit",
        value: "i-linear-calendar-edit",
      },
      {
        label: "link",
        value: "i-linear-link",
      },
      {
        label: "category",
        value: "i-linear-category",
      },
    ],
    icons: [
      {
        label: "activity",
        value: "i-linear-activity",
      },
      {
        label: "add",
        value: "i-linear-add",
      },
      {
        label: "add-circle",
        value: "i-linear-add-circle",
      },
      {
        label: "additem",
        value: "i-linear-additem",
      },
      {
        label: "add-square",
        value: "i-linear-add-square",
      },
      {
        label: "airdrop",
        value: "i-linear-airdrop",
      },
      {
        label: "airplane",
        value: "i-linear-airplane",
      },
      {
        label: "airplane-square",
        value: "i-linear-airplane-square",
      },
      {
        label: "airpod",
        value: "i-linear-airpod",
      },
      {
        label: "airpods",
        value: "i-linear-airpods",
      },
      {
        label: "alarm",
        value: "i-linear-alarm",
      },
      {
        label: "align-bottom",
        value: "i-linear-align-bottom",
      },
      {
        label: "align-horizontally",
        value: "i-linear-align-horizontally",
      },
      {
        label: "align-left",
        value: "i-linear-align-left",
      },
      {
        label: "align-right",
        value: "i-linear-align-right",
      },
      {
        label: "align-vertically",
        value: "i-linear-align-vertically",
      },
      {
        label: "aquarius",
        value: "i-linear-aquarius",
      },
      {
        label: "archive",
        value: "i-linear-archive",
      },
      {
        label: "archive-add",
        value: "i-linear-archive-add",
      },
      {
        label: "archive-book",
        value: "i-linear-archive-book",
      },
      {
        label: "archive-minus",
        value: "i-linear-archive-minus",
      },
      {
        label: "archive-slash",
        value: "i-linear-archive-slash",
      },
      {
        label: "archive-tick",
        value: "i-linear-archive-tick",
      },
      {
        label: "arrange-circle",
        value: "i-linear-arrange-circle",
      },
      {
        label: "arrange-square",
        value: "i-linear-arrange-square",
      },
      {
        label: "arrow",
        value: "i-linear-arrow",
      },
      {
        label: "arrow-bottom",
        value: "i-linear-arrow-bottom",
      },
      {
        label: "arrow-circle-down",
        value: "i-linear-arrow-circle-down",
      },
      {
        label: "arrow-circle-left",
        value: "i-linear-arrow-circle-left",
      },
      {
        label: "arrow-circle-right",
        value: "i-linear-arrow-circle-right",
      },
      {
        label: "arrow-down",
        value: "i-linear-arrow-down",
      },
      {
        label: "arrow-left",
        value: "i-linear-arrow-left",
      },
      {
        label: "arrow-right",
        value: "i-linear-arrow-right",
      },
      {
        label: "arrow-square",
        value: "i-linear-arrow-square",
      },
      {
        label: "arrow-square-down",
        value: "i-linear-arrow-square-down",
      },
      {
        label: "arrow-square-left",
        value: "i-linear-arrow-square-left",
      },
      {
        label: "arrow-square-up",
        value: "i-linear-arrow-square-up",
      },
      {
        label: "arrow-swap-horizontal",
        value: "i-linear-arrow-swap-horizontal",
      },
      {
        label: "arrow-up",
        value: "i-linear-arrow-up",
      },
      {
        label: "attach-circle",
        value: "i-linear-attach-circle",
      },
      {
        label: "attach-square",
        value: "i-linear-attach-square",
      },
      {
        label: "audio-square",
        value: "i-linear-audio-square",
      },
      {
        label: "autobrightness",
        value: "i-linear-autobrightness",
      },
      {
        label: "award",
        value: "i-linear-award",
      },
      {
        label: "back-square",
        value: "i-linear-back-square",
      },
      {
        label: "backward",
        value: "i-linear-backward",
      },
      {
        label: "backward-item",
        value: "i-linear-backward-item",
      },
      {
        label: "bag",
        value: "i-linear-bag",
      },
      {
        label: "bag-cross",
        value: "i-linear-bag-cross",
      },
      {
        label: "bag-happy",
        value: "i-linear-bag-happy",
      },
      {
        label: "bag-tick",
        value: "i-linear-bag-tick",
      },
      {
        label: "bag-timer",
        value: "i-linear-bag-timer",
      },
      {
        label: "bank",
        value: "i-linear-bank",
      },
      {
        label: "barcode",
        value: "i-linear-barcode",
      },
      {
        label: "battery-charging",
        value: "i-linear-battery-charging",
      },
      {
        label: "battery-disable",
        value: "i-linear-battery-disable",
      },
      {
        label: "battery-empty",
        value: "i-linear-battery-empty",
      },
      {
        label: "battery-full",
        value: "i-linear-battery-full",
      },
      {
        label: "bezier",
        value: "i-linear-bezier",
      },
      {
        label: "bill",
        value: "i-linear-bill",
      },
      {
        label: "bitcoin-card",
        value: "i-linear-bitcoin-card",
      },
      {
        label: "bitcoin-convert",
        value: "i-linear-bitcoin-convert",
      },
      {
        label: "bitcoin-refresh",
        value: "i-linear-bitcoin-refresh",
      },
      {
        label: "blend",
        value: "i-linear-blend",
      },
      {
        label: "bluetooth",
        value: "i-linear-bluetooth",
      },
      {
        label: "bluetooth-circle",
        value: "i-linear-bluetooth-circle",
      },
      {
        label: "bluetooth-rectangle",
        value: "i-linear-bluetooth-rectangle",
      },
      {
        label: "blur",
        value: "i-linear-blur",
      },
      {
        label: "book",
        value: "i-linear-book",
      },
      {
        label: "bookmark",
        value: "i-linear-bookmark",
      },
      {
        label: "book-saved",
        value: "i-linear-book-saved",
      },
      {
        label: "book-square",
        value: "i-linear-book-square",
      },
      {
        label: "box",
        value: "i-linear-box",
      },
      {
        label: "box-add",
        value: "i-linear-box-add",
      },
      {
        label: "box-remove",
        value: "i-linear-box-remove",
      },
      {
        label: "box-search",
        value: "i-linear-box-search",
      },
      {
        label: "box-tick",
        value: "i-linear-box-tick",
      },
      {
        label: "box-time",
        value: "i-linear-box-time",
      },
      {
        label: "briefcase",
        value: "i-linear-briefcase",
      },
      {
        label: "brifecase-cross",
        value: "i-linear-brifecase-cross",
      },
      {
        label: "brifecase-tick",
        value: "i-linear-brifecase-tick",
      },
      {
        label: "brifecase-timer",
        value: "i-linear-brifecase-timer",
      },
      {
        label: "broom",
        value: "i-linear-broom",
      },
      {
        label: "brush",
        value: "i-linear-brush",
      },
      {
        label: "bubble",
        value: "i-linear-bubble",
      },
      {
        label: "bucket-circle",
        value: "i-linear-bucket-circle",
      },
      {
        label: "bucket-square",
        value: "i-linear-bucket-square",
      },
      {
        label: "building",
        value: "i-linear-building",
      },
      {
        label: "buildings",
        value: "i-linear-buildings",
      },
      {
        label: "buliding",
        value: "i-linear-buliding",
      },
      {
        label: "bus",
        value: "i-linear-bus",
      },
      {
        label: "buy-crypto",
        value: "i-linear-buy-crypto",
      },
      {
        label: "cake",
        value: "i-linear-cake",
      },
      {
        label: "calculator",
        value: "i-linear-calculator",
      },
      {
        label: "calendar",
        value: "i-linear-calendar",
      },
      {
        label: "calendar-add",
        value: "i-linear-calendar-add",
      },
      {
        label: "calendar-circle",
        value: "i-linear-calendar-circle",
      },
      {
        label: "calendar-edit",
        value: "i-linear-calendar-edit",
      },
      {
        label: "calendar-remove",
        value: "i-linear-calendar-remove",
      },
      {
        label: "calendar-search",
        value: "i-linear-calendar-search",
      },
      {
        label: "calendar-tick",
        value: "i-linear-calendar-tick",
      },
      {
        label: "call",
        value: "i-linear-call",
      },
      {
        label: "call-add",
        value: "i-linear-call-add",
      },
      {
        label: "call-calling",
        value: "i-linear-call-calling",
      },
      {
        label: "call-incoming",
        value: "i-linear-call-incoming",
      },
      {
        label: "call-minus",
        value: "i-linear-call-minus",
      },
      {
        label: "call-outgoing",
        value: "i-linear-call-outgoing",
      },
      {
        label: "call-received",
        value: "i-linear-call-received",
      },
      {
        label: "call-remove",
        value: "i-linear-call-remove",
      },
      {
        label: "call-slash",
        value: "i-linear-call-slash",
      },
      {
        label: "camera",
        value: "i-linear-camera",
      },
      {
        label: "camera-slash",
        value: "i-linear-camera-slash",
      },
      {
        label: "candle",
        value: "i-linear-candle",
      },
      {
        label: "car",
        value: "i-linear-car",
      },
      {
        label: "card",
        value: "i-linear-card",
      },
      {
        label: "card-add",
        value: "i-linear-card-add",
      },
      {
        label: "card-coin",
        value: "i-linear-card-coin",
      },
      {
        label: "card-edit",
        value: "i-linear-card-edit",
      },
      {
        label: "card-pos",
        value: "i-linear-card-pos",
      },
      {
        label: "card-receive",
        value: "i-linear-card-receive",
      },
      {
        label: "card-remove",
        value: "i-linear-card-remove",
      },
      {
        label: "cards",
        value: "i-linear-cards",
      },
      {
        label: "card-send",
        value: "i-linear-card-send",
      },
      {
        label: "card-slash",
        value: "i-linear-card-slash",
      },
      {
        label: "card-tick",
        value: "i-linear-card-tick",
      },
      {
        label: "category",
        value: "i-linear-category",
      },
      {
        label: "cd",
        value: "i-linear-cd",
      },
      {
        label: "chart",
        value: "i-linear-chart",
      },
      {
        label: "chart-square",
        value: "i-linear-chart-square",
      },
      {
        label: "chart-success",
        value: "i-linear-chart-success",
      },
      {
        label: "check",
        value: "i-linear-check",
      },
      {
        label: "chrome",
        value: "i-linear-chrome",
      },
      {
        label: "clipboard",
        value: "i-linear-clipboard",
      },
      {
        label: "clipboard-close",
        value: "i-linear-clipboard-close",
      },
      {
        label: "clipboard-export",
        value: "i-linear-clipboard-export",
      },
      {
        label: "clipboard-import",
        value: "i-linear-clipboard-import",
      },
      {
        label: "clipboard-text",
        value: "i-linear-clipboard-text",
      },
      {
        label: "clipboard-tick",
        value: "i-linear-clipboard-tick",
      },
      {
        label: "clock",
        value: "i-linear-clock",
      },
      {
        label: "close-circle",
        value: "i-linear-close-circle",
      },
      {
        label: "close-square",
        value: "i-linear-close-square",
      },
      {
        label: "cloud",
        value: "i-linear-cloud",
      },
      {
        label: "cloud-add",
        value: "i-linear-cloud-add",
      },
      {
        label: "cloud-change",
        value: "i-linear-cloud-change",
      },
      {
        label: "cloud-connection",
        value: "i-linear-cloud-connection",
      },
      {
        label: "cloud-cross",
        value: "i-linear-cloud-cross",
      },
      {
        label: "cloud-drizzle",
        value: "i-linear-cloud-drizzle",
      },
      {
        label: "cloud-fog",
        value: "i-linear-cloud-fog",
      },
      {
        label: "cloud-lightning",
        value: "i-linear-cloud-lightning",
      },
      {
        label: "cloud-minus",
        value: "i-linear-cloud-minus",
      },
      {
        label: "cloud-notif",
        value: "i-linear-cloud-notif",
      },
      {
        label: "cloud-plus",
        value: "i-linear-cloud-plus",
      },
      {
        label: "cloud-remove",
        value: "i-linear-cloud-remove",
      },
      {
        label: "cloud-snow",
        value: "i-linear-cloud-snow",
      },
      {
        label: "cloud-sunny",
        value: "i-linear-cloud-sunny",
      },
      {
        label: "code",
        value: "i-linear-code",
      },
      {
        label: "code-circle",
        value: "i-linear-code-circle",
      },
      {
        label: "coffee",
        value: "i-linear-coffee",
      },
      {
        label: "coin",
        value: "i-linear-coin",
      },
      {
        label: "colorfilter",
        value: "i-linear-colorfilter",
      },
      {
        label: "colors-square",
        value: "i-linear-colors-square",
      },
      {
        label: "color-swatch",
        value: "i-linear-color-swatch",
      },
      {
        label: "command",
        value: "i-linear-command",
      },
      {
        label: "command-square",
        value: "i-linear-command-square",
      },
      {
        label: "component",
        value: "i-linear-component",
      },
      {
        label: "computing",
        value: "i-linear-computing",
      },
      {
        label: "convert",
        value: "i-linear-convert",
      },
      {
        label: "convertshape",
        value: "i-linear-convertshape",
      },
      {
        label: "copy",
        value: "i-linear-copy",
      },
      {
        label: "copyright",
        value: "i-linear-copyright",
      },
      {
        label: "copy-success",
        value: "i-linear-copy-success",
      },
      {
        label: "courthouse",
        value: "i-linear-courthouse",
      },
      {
        label: "cpu",
        value: "i-linear-cpu",
      },
      {
        label: "cpu-charge",
        value: "i-linear-cpu-charge",
      },
      {
        label: "cpu-setting",
        value: "i-linear-cpu-setting",
      },
      {
        label: "creative-commons",
        value: "i-linear-creative-commons",
      },
      {
        label: "crop",
        value: "i-linear-crop",
      },
      {
        label: "crown",
        value: "i-linear-crown",
      },
      {
        label: "cup",
        value: "i-linear-cup",
      },
      {
        label: "danger",
        value: "i-linear-danger",
      },
      {
        label: "data",
        value: "i-linear-data",
      },
      {
        label: "designtools",
        value: "i-linear-designtools",
      },
      {
        label: "device-message",
        value: "i-linear-device-message",
      },
      {
        label: "devices",
        value: "i-linear-devices",
      },
      {
        label: "diagram",
        value: "i-linear-diagram",
      },
      {
        label: "diamonds",
        value: "i-linear-diamonds",
      },
      {
        label: "direct",
        value: "i-linear-direct",
      },
      {
        label: "directbox-default",
        value: "i-linear-directbox-default",
      },
      {
        label: "directbox-notif",
        value: "i-linear-directbox-notif",
      },
      {
        label: "directbox-receive",
        value: "i-linear-directbox-receive",
      },
      {
        label: "directbox-send",
        value: "i-linear-directbox-send",
      },
      {
        label: "direct-down",
        value: "i-linear-direct-down",
      },
      {
        label: "direct-inbox",
        value: "i-linear-direct-inbox",
      },
      {
        label: "direct-left",
        value: "i-linear-direct-left",
      },
      {
        label: "direct-normal",
        value: "i-linear-direct-normal",
      },
      {
        label: "direct-notification",
        value: "i-linear-direct-notification",
      },
      {
        label: "direct-right",
        value: "i-linear-direct-right",
      },
      {
        label: "direct-send",
        value: "i-linear-direct-send",
      },
      {
        label: "direct-up",
        value: "i-linear-direct-up",
      },
      {
        label: "discount-circle",
        value: "i-linear-discount-circle",
      },
      {
        label: "discount-shape",
        value: "i-linear-discount-shape",
      },
      {
        label: "discover",
        value: "i-linear-discover",
      },
      {
        label: "dislike",
        value: "i-linear-dislike",
      },
      {
        label: "document",
        value: "i-linear-document",
      },
      {
        label: "document-cloud",
        value: "i-linear-document-cloud",
      },
      {
        label: "document-code",
        value: "i-linear-document-code",
      },
      {
        label: "document-copy",
        value: "i-linear-document-copy",
      },
      {
        label: "document-download",
        value: "i-linear-document-download",
      },
      {
        label: "document-favorite",
        value: "i-linear-document-favorite",
      },
      {
        label: "document-filter",
        value: "i-linear-document-filter",
      },
      {
        label: "document-forward",
        value: "i-linear-document-forward",
      },
      {
        label: "document-like",
        value: "i-linear-document-like",
      },
      {
        label: "document-normal",
        value: "i-linear-document-normal",
      },
      {
        label: "document-previous",
        value: "i-linear-document-previous",
      },
      {
        label: "document-sketch",
        value: "i-linear-document-sketch",
      },
      {
        label: "document-text",
        value: "i-linear-document-text",
      },
      {
        label: "document-upload",
        value: "i-linear-document-upload",
      },
      {
        label: "dollar-circle",
        value: "i-linear-dollar-circle",
      },
      {
        label: "dollar-square",
        value: "i-linear-dollar-square",
      },
      {
        label: "driver",
        value: "i-linear-driver",
      },
      {
        label: "driver-refresh",
        value: "i-linear-driver-refresh",
      },
      {
        label: "driving",
        value: "i-linear-driving",
      },
      {
        label: "edit",
        value: "i-linear-edit",
      },
      {
        label: "electricity",
        value: "i-linear-electricity",
      },
      {
        label: "element-equal",
        value: "i-linear-element-equal",
      },
      {
        label: "element-plus",
        value: "i-linear-element-plus",
      },
      {
        label: "emoji-happy",
        value: "i-linear-emoji-happy",
      },
      {
        label: "emoji-normal",
        value: "i-linear-emoji-normal",
      },
      {
        label: "emoji-sad",
        value: "i-linear-emoji-sad",
      },
      {
        label: "empty-wallet",
        value: "i-linear-empty-wallet",
      },
      {
        label: "empty-wallet-add",
        value: "i-linear-empty-wallet-add",
      },
      {
        label: "empty-wallet-change",
        value: "i-linear-empty-wallet-change",
      },
      {
        label: "empty-wallet-remove",
        value: "i-linear-empty-wallet-remove",
      },
      {
        label: "empty-wallet-tick",
        value: "i-linear-empty-wallet-tick",
      },
      {
        label: "empty-wallet-time",
        value: "i-linear-empty-wallet-time",
      },
      {
        label: "eraser",
        value: "i-linear-eraser",
      },
      {
        label: "export",
        value: "i-linear-export",
      },
      {
        label: "external-drive",
        value: "i-linear-external-drive",
      },
      {
        label: "eye",
        value: "i-linear-eye",
      },
      {
        label: "eye-slash",
        value: "i-linear-eye-slash",
      },
      {
        label: "fatrows",
        value: "i-linear-fatrows",
      },
      {
        label: "favorite-chart",
        value: "i-linear-favorite-chart",
      },
      {
        label: "filter",
        value: "i-linear-filter",
      },
      {
        label: "filter-add",
        value: "i-linear-filter-add",
      },
      {
        label: "filter-edit",
        value: "i-linear-filter-edit",
      },
      {
        label: "filter-remove",
        value: "i-linear-filter-remove",
      },
      {
        label: "filter-search",
        value: "i-linear-filter-search",
      },
      {
        label: "filter-square",
        value: "i-linear-filter-square",
      },
      {
        label: "filter-tick",
        value: "i-linear-filter-tick",
      },
      {
        label: "finger-cricle",
        value: "i-linear-finger-cricle",
      },
      {
        label: "finger-scan",
        value: "i-linear-finger-scan",
      },
      {
        label: "firstline",
        value: "i-linear-firstline",
      },
      {
        label: "flag",
        value: "i-linear-flag",
      },
      {
        label: "flash",
        value: "i-linear-flash",
      },
      {
        label: "flash-circle",
        value: "i-linear-flash-circle",
      },
      {
        label: "flash-slash",
        value: "i-linear-flash-slash",
      },
      {
        label: "folder",
        value: "i-linear-folder",
      },
      {
        label: "folder-add",
        value: "i-linear-folder-add",
      },
      {
        label: "folder-cloud",
        value: "i-linear-folder-cloud",
      },
      {
        label: "folder-connection",
        value: "i-linear-folder-connection",
      },
      {
        label: "folder-cross",
        value: "i-linear-folder-cross",
      },
      {
        label: "folder-favorite",
        value: "i-linear-folder-favorite",
      },
      {
        label: "folder-minus",
        value: "i-linear-folder-minus",
      },
      {
        label: "folder-open",
        value: "i-linear-folder-open",
      },
      {
        label: "format-circle",
        value: "i-linear-format-circle",
      },
      {
        label: "format-square",
        value: "i-linear-format-square",
      },
      {
        label: "forward",
        value: "i-linear-forward",
      },
      {
        label: "forward-item",
        value: "i-linear-forward-item",
      },
      {
        label: "forward-square",
        value: "i-linear-forward-square",
      },
      {
        label: "frame",
        value: "i-linear-frame",
      },
      {
        label: "gallery",
        value: "i-linear-gallery",
      },
      {
        label: "gallery-add",
        value: "i-linear-gallery-add",
      },
      {
        label: "gallery-edit",
        value: "i-linear-gallery-edit",
      },
      {
        label: "gallery-export",
        value: "i-linear-gallery-export",
      },
      {
        label: "gallery-favorite",
        value: "i-linear-gallery-favorite",
      },
      {
        label: "gallery-import",
        value: "i-linear-gallery-import",
      },
      {
        label: "gallery-remove",
        value: "i-linear-gallery-remove",
      },
      {
        label: "gallery-slash",
        value: "i-linear-gallery-slash",
      },
      {
        label: "gallery-tick",
        value: "i-linear-gallery-tick",
      },
      {
        label: "game",
        value: "i-linear-game",
      },
      {
        label: "gameboy",
        value: "i-linear-gameboy",
      },
      {
        label: "gas-station",
        value: "i-linear-gas-station",
      },
      {
        label: "gemini",
        value: "i-linear-gemini",
      },
      {
        label: "ghost",
        value: "i-linear-ghost",
      },
      {
        label: "gift",
        value: "i-linear-gift",
      },
      {
        label: "glass",
        value: "i-linear-glass",
      },
      {
        label: "global",
        value: "i-linear-global",
      },
      {
        label: "global-edit",
        value: "i-linear-global-edit",
      },
      {
        label: "global-refresh",
        value: "i-linear-global-refresh",
      },
      {
        label: "global-search",
        value: "i-linear-global-search",
      },
      {
        label: "gps",
        value: "i-linear-gps",
      },
      {
        label: "gps-slash",
        value: "i-linear-gps-slash",
      },
      {
        label: "grammerly",
        value: "i-linear-grammerly",
      },
      {
        label: "graph",
        value: "i-linear-graph",
      },
      {
        label: "grid-edit",
        value: "i-linear-grid-edit",
      },
      {
        label: "grid-eraser",
        value: "i-linear-grid-eraser",
      },
      {
        label: "grid-lock",
        value: "i-linear-grid-lock",
      },
      {
        label: "happyemoji",
        value: "i-linear-happyemoji",
      },
      {
        label: "hashtag",
        value: "i-linear-hashtag",
      },
      {
        label: "hashtag-down",
        value: "i-linear-hashtag-down",
      },
      {
        label: "hashtag-up",
        value: "i-linear-hashtag-up",
      },
      {
        label: "headphone",
        value: "i-linear-headphone",
      },
      {
        label: "headphones",
        value: "i-linear-headphones",
      },
      {
        label: "health",
        value: "i-linear-health",
      },
      {
        label: "heart",
        value: "i-linear-heart",
      },
      {
        label: "heart-add",
        value: "i-linear-heart-add",
      },
      {
        label: "heart-circle",
        value: "i-linear-heart-circle",
      },
      {
        label: "heart-edit",
        value: "i-linear-heart-edit",
      },
      {
        label: "heart-remove",
        value: "i-linear-heart-remove",
      },
      {
        label: "heart-search",
        value: "i-linear-heart-search",
      },
      {
        label: "heart-slash",
        value: "i-linear-heart-slash",
      },
      {
        label: "heart-tick",
        value: "i-linear-heart-tick",
      },
      {
        label: "hierarchy",
        value: "i-linear-hierarchy",
      },
      {
        label: "hierarchy-square",
        value: "i-linear-hierarchy-square",
      },
      {
        label: "home",
        value: "i-linear-home",
      },
      {
        label: "home-hashtag",
        value: "i-linear-home-hashtag",
      },
      {
        label: "home-trend-down",
        value: "i-linear-home-trend-down",
      },
      {
        label: "home-trend-up",
        value: "i-linear-home-trend-up",
      },
      {
        label: "home-wifi",
        value: "i-linear-home-wifi",
      },
      {
        label: "hospital",
        value: "i-linear-hospital",
      },
      {
        label: "house",
        value: "i-linear-house",
      },
      {
        label: "image",
        value: "i-linear-image",
      },
      {
        label: "import",
        value: "i-linear-import",
      },
      {
        label: "info-circle",
        value: "i-linear-info-circle",
      },
      {
        label: "information",
        value: "i-linear-information",
      },
      {
        label: "instagram",
        value: "i-linear-instagram",
      },
      {
        label: "judge",
        value: "i-linear-judge",
      },
      {
        label: "kanban",
        value: "i-linear-kanban",
      },
      {
        label: "key",
        value: "i-linear-key",
      },
      {
        label: "keyboard",
        value: "i-linear-keyboard",
      },
      {
        label: "keyboard-open",
        value: "i-linear-keyboard-open",
      },
      {
        label: "key-square",
        value: "i-linear-key-square",
      },
      {
        label: "lamp",
        value: "i-linear-lamp",
      },
      {
        label: "lamp-charge",
        value: "i-linear-lamp-charge",
      },
      {
        label: "lamp-on",
        value: "i-linear-lamp-on",
      },
      {
        label: "lamp-slash",
        value: "i-linear-lamp-slash",
      },
      {
        label: "language-circle",
        value: "i-linear-language-circle",
      },
      {
        label: "language-square",
        value: "i-linear-language-square",
      },
      {
        label: "layer",
        value: "i-linear-layer",
      },
      {
        label: "level",
        value: "i-linear-level",
      },
      {
        label: "lifebuoy",
        value: "i-linear-lifebuoy",
      },
      {
        label: "like",
        value: "i-linear-like",
      },
      {
        label: "like-dislike",
        value: "i-linear-like-dislike",
      },
      {
        label: "like-shapes",
        value: "i-linear-like-shapes",
      },
      {
        label: "like-tag",
        value: "i-linear-like-tag",
      },
      {
        label: "link",
        value: "i-linear-link",
      },
      {
        label: "link-circle",
        value: "i-linear-link-circle",
      },
      {
        label: "link-square",
        value: "i-linear-link-square",
      },
      {
        label: "location",
        value: "i-linear-location",
      },
      {
        label: "location-add",
        value: "i-linear-location-add",
      },
      {
        label: "location-cross",
        value: "i-linear-location-cross",
      },
      {
        label: "location-minus",
        value: "i-linear-location-minus",
      },
      {
        label: "location-slash",
        value: "i-linear-location-slash",
      },
      {
        label: "location-tick",
        value: "i-linear-location-tick",
      },
      {
        label: "lock",
        value: "i-linear-lock",
      },
      {
        label: "lock-circle",
        value: "i-linear-lock-circle",
      },
      {
        label: "lock-slash",
        value: "i-linear-lock-slash",
      },
      {
        label: "login",
        value: "i-linear-login",
      },
      {
        label: "logout",
        value: "i-linear-logout",
      },
      {
        label: "lovely",
        value: "i-linear-lovely",
      },
      {
        label: "magicpen",
        value: "i-linear-magicpen",
      },
      {
        label: "magic-star",
        value: "i-linear-magic-star",
      },
      {
        label: "main-component",
        value: "i-linear-main-component",
      },
      {
        label: "man",
        value: "i-linear-man",
      },
      {
        label: "map",
        value: "i-linear-map",
      },
      {
        label: "mask",
        value: "i-linear-mask",
      },
      {
        label: "math",
        value: "i-linear-math",
      },
      {
        label: "maximize",
        value: "i-linear-maximize",
      },
      {
        label: "maximize-circle",
        value: "i-linear-maximize-circle",
      },
      {
        label: "medal",
        value: "i-linear-medal",
      },
      {
        label: "medal-star",
        value: "i-linear-medal-star",
      },
      {
        label: "menu",
        value: "i-linear-menu",
      },
      {
        label: "menu-board",
        value: "i-linear-menu-board",
      },
      {
        label: "message",
        value: "i-linear-message",
      },
      {
        label: "message-add",
        value: "i-linear-message-add",
      },
      {
        label: "message-circle",
        value: "i-linear-message-circle",
      },
      {
        label: "message-edit",
        value: "i-linear-message-edit",
      },
      {
        label: "message-favorite",
        value: "i-linear-message-favorite",
      },
      {
        label: "message-minus",
        value: "i-linear-message-minus",
      },
      {
        label: "message-notif",
        value: "i-linear-message-notif",
      },
      {
        label: "message-programming",
        value: "i-linear-message-programming",
      },
      {
        label: "message-question",
        value: "i-linear-message-question",
      },
      {
        label: "message-remove",
        value: "i-linear-message-remove",
      },
      {
        label: "messages",
        value: "i-linear-messages",
      },
      {
        label: "message-search",
        value: "i-linear-message-search",
      },
      {
        label: "message-square",
        value: "i-linear-message-square",
      },
      {
        label: "message-text",
        value: "i-linear-message-text",
      },
      {
        label: "message-tick",
        value: "i-linear-message-tick",
      },
      {
        label: "message-time",
        value: "i-linear-message-time",
      },
      {
        label: "microphone",
        value: "i-linear-microphone",
      },
      {
        label: "microphone-slash",
        value: "i-linear-microphone-slash",
      },
      {
        label: "microscope",
        value: "i-linear-microscope",
      },
      {
        label: "milk",
        value: "i-linear-milk",
      },
      {
        label: "mini-music-sqaure",
        value: "i-linear-mini-music-sqaure",
      },
      {
        label: "minus",
        value: "i-linear-minus",
      },
      {
        label: "minus-cirlce",
        value: "i-linear-minus-cirlce",
      },
      {
        label: "minus-square",
        value: "i-linear-minus-square",
      },
      {
        label: "mirror",
        value: "i-linear-mirror",
      },
      {
        label: "mirroring-screen",
        value: "i-linear-mirroring-screen",
      },
      {
        label: "mobile",
        value: "i-linear-mobile",
      },
      {
        label: "mobile-programming",
        value: "i-linear-mobile-programming",
      },
      {
        label: "money",
        value: "i-linear-money",
      },
      {
        label: "money-add",
        value: "i-linear-money-add",
      },
      {
        label: "money-change",
        value: "i-linear-money-change",
      },
      {
        label: "money-forbidden",
        value: "i-linear-money-forbidden",
      },
      {
        label: "money-recive",
        value: "i-linear-money-recive",
      },
      {
        label: "money-remove",
        value: "i-linear-money-remove",
      },
      {
        label: "moneys",
        value: "i-linear-moneys",
      },
      {
        label: "money-send",
        value: "i-linear-money-send",
      },
      {
        label: "money-tick",
        value: "i-linear-money-tick",
      },
      {
        label: "money-time",
        value: "i-linear-money-time",
      },
      {
        label: "monitor",
        value: "i-linear-monitor",
      },
      {
        label: "monitor-mobbile",
        value: "i-linear-monitor-mobbile",
      },
      {
        label: "monitor-recorder",
        value: "i-linear-monitor-recorder",
      },
      {
        label: "moon",
        value: "i-linear-moon",
      },
      {
        label: "more",
        value: "i-linear-more",
      },
      {
        label: "more-circle",
        value: "i-linear-more-circle",
      },
      {
        label: "more-square",
        value: "i-linear-more-square",
      },
      {
        label: "mouse",
        value: "i-linear-mouse",
      },
      {
        label: "mouse-circle",
        value: "i-linear-mouse-circle",
      },
      {
        label: "mouse-square",
        value: "i-linear-mouse-square",
      },
      {
        label: "music",
        value: "i-linear-music",
      },
      {
        label: "music-circle",
        value: "i-linear-music-circle",
      },
      {
        label: "music-dashboard",
        value: "i-linear-music-dashboard",
      },
      {
        label: "music-filter",
        value: "i-linear-music-filter",
      },
      {
        label: "musicnote",
        value: "i-linear-musicnote",
      },
      {
        label: "music-play",
        value: "i-linear-music-play",
      },
      {
        label: "music-playlist",
        value: "i-linear-music-playlist",
      },
      {
        label: "music-square",
        value: "i-linear-music-square",
      },
      {
        label: "music-square-add",
        value: "i-linear-music-square-add",
      },
      {
        label: "music-square-remove",
        value: "i-linear-music-square-remove",
      },
      {
        label: "music-square-search",
        value: "i-linear-music-square-search",
      },
      {
        label: "next",
        value: "i-linear-next",
      },
      {
        label: "note",
        value: "i-linear-note",
      },
      {
        label: "note-add",
        value: "i-linear-note-add",
      },
      {
        label: "note-favorite",
        value: "i-linear-note-favorite",
      },
      {
        label: "note-remove",
        value: "i-linear-note-remove",
      },
      {
        label: "note-square",
        value: "i-linear-note-square",
      },
      {
        label: "note-text",
        value: "i-linear-note-text",
      },
      {
        label: "notification",
        value: "i-linear-notification",
      },
      {
        label: "notification-bing",
        value: "i-linear-notification-bing",
      },
      {
        label: "notification-circle",
        value: "i-linear-notification-circle",
      },
      {
        label: "notification-favorite",
        value: "i-linear-notification-favorite",
      },
      {
        label: "notification-status",
        value: "i-linear-notification-status",
      },
      {
        label: "omega-circle",
        value: "i-linear-omega-circle",
      },
      {
        label: "omega-square",
        value: "i-linear-omega-square",
      },
      {
        label: "paintbucket",
        value: "i-linear-paintbucket",
      },
      {
        label: "paperclip",
        value: "i-linear-paperclip",
      },
      {
        label: "password-check",
        value: "i-linear-password-check",
      },
      {
        label: "path",
        value: "i-linear-path",
      },
      {
        label: "path-square",
        value: "i-linear-path-square",
      },
      {
        label: "pause",
        value: "i-linear-pause",
      },
      {
        label: "pause-circle",
        value: "i-linear-pause-circle",
      },
      {
        label: "pen-add",
        value: "i-linear-pen-add",
      },
      {
        label: "pen-close",
        value: "i-linear-pen-close",
      },
      {
        label: "pen-remove",
        value: "i-linear-pen-remove",
      },
      {
        label: "pen-tool",
        value: "i-linear-pen-tool",
      },
      {
        label: "people",
        value: "i-linear-people",
      },
      {
        label: "percentage-square",
        value: "i-linear-percentage-square",
      },
      {
        label: "personalcard",
        value: "i-linear-personalcard",
      },
      {
        label: "pet",
        value: "i-linear-pet",
      },
      {
        label: "pharagraphspacing",
        value: "i-linear-pharagraphspacing",
      },
      {
        label: "picture-frame",
        value: "i-linear-picture-frame",
      },
      {
        label: "play",
        value: "i-linear-play",
      },
      {
        label: "play-add",
        value: "i-linear-play-add",
      },
      {
        label: "play-circle",
        value: "i-linear-play-circle",
      },
      {
        label: "play-cricle",
        value: "i-linear-play-cricle",
      },
      {
        label: "play-remove",
        value: "i-linear-play-remove",
      },
      {
        label: "presention-chart",
        value: "i-linear-presention-chart",
      },
      {
        label: "previous",
        value: "i-linear-previous",
      },
      {
        label: "printer",
        value: "i-linear-printer",
      },
      {
        label: "printer-slash",
        value: "i-linear-printer-slash",
      },
      {
        label: "profile-add",
        value: "i-linear-profile-add",
      },
      {
        label: "profile-circle",
        value: "i-linear-profile-circle",
      },
      {
        label: "profile-delete",
        value: "i-linear-profile-delete",
      },
      {
        label: "profile-remove",
        value: "i-linear-profile-remove",
      },
      {
        label: "profile-tick",
        value: "i-linear-profile-tick",
      },
      {
        label: "programming-arrow",
        value: "i-linear-programming-arrow",
      },
      {
        label: "programming-arrows",
        value: "i-linear-programming-arrows",
      },
      {
        label: "quote-down",
        value: "i-linear-quote-down",
      },
      {
        label: "quote-down-circle",
        value: "i-linear-quote-down-circle",
      },
      {
        label: "quote-down-square",
        value: "i-linear-quote-down-square",
      },
      {
        label: "quote-up",
        value: "i-linear-quote-up",
      },
      {
        label: "quote-up-circle",
        value: "i-linear-quote-up-circle",
      },
      {
        label: "quote-up-square",
        value: "i-linear-quote-up-square",
      },
      {
        label: "radar",
        value: "i-linear-radar",
      },
      {
        label: "radio",
        value: "i-linear-radio",
      },
      {
        label: "ram",
        value: "i-linear-ram",
      },
      {
        label: "ranking",
        value: "i-linear-ranking",
      },
      {
        label: "receipt",
        value: "i-linear-receipt",
      },
      {
        label: "receipt-add",
        value: "i-linear-receipt-add",
      },
      {
        label: "receipt-discount",
        value: "i-linear-receipt-discount",
      },
      {
        label: "receipt-disscount",
        value: "i-linear-receipt-disscount",
      },
      {
        label: "receipt-edit",
        value: "i-linear-receipt-edit",
      },
      {
        label: "receipt-item",
        value: "i-linear-receipt-item",
      },
      {
        label: "receipt-minus",
        value: "i-linear-receipt-minus",
      },
      {
        label: "receipt-search",
        value: "i-linear-receipt-search",
      },
      {
        label: "receipt-square",
        value: "i-linear-receipt-square",
      },
      {
        label: "receipt-text",
        value: "i-linear-receipt-text",
      },
      {
        label: "received",
        value: "i-linear-received",
      },
      {
        label: "receive-square",
        value: "i-linear-receive-square",
      },
      {
        label: "record",
        value: "i-linear-record",
      },
      {
        label: "record-circle",
        value: "i-linear-record-circle",
      },
      {
        label: "recovery-convert",
        value: "i-linear-recovery-convert",
      },
      {
        label: "redo",
        value: "i-linear-redo",
      },
      {
        label: "refresh",
        value: "i-linear-refresh",
      },
      {
        label: "refresh-circle",
        value: "i-linear-refresh-circle",
      },
      {
        label: "refresh-left-square",
        value: "i-linear-refresh-left-square",
      },
      {
        label: "refresh-right-square",
        value: "i-linear-refresh-right-square",
      },
      {
        label: "repeat",
        value: "i-linear-repeat",
      },
      {
        label: "repeat-circle",
        value: "i-linear-repeat-circle",
      },
      {
        label: "repeate-music",
        value: "i-linear-repeate-music",
      },
      {
        label: "repeate-one",
        value: "i-linear-repeate-one",
      },
      {
        label: "reserve",
        value: "i-linear-reserve",
      },
      {
        label: "rotate-left",
        value: "i-linear-rotate-left",
      },
      {
        label: "rotate-right",
        value: "i-linear-rotate-right",
      },
      {
        label: "route-square",
        value: "i-linear-route-square",
      },
      {
        label: "routing",
        value: "i-linear-routing",
      },
      {
        label: "row-horizontal",
        value: "i-linear-row-horizontal",
      },
      {
        label: "row-vertical",
        value: "i-linear-row-vertical",
      },
      {
        label: "ruler",
        value: "i-linear-ruler",
      },
      {
        label: "safe-home",
        value: "i-linear-safe-home",
      },
      {
        label: "sagittarius",
        value: "i-linear-sagittarius",
      },
      {
        label: "save-add",
        value: "i-linear-save-add",
      },
      {
        label: "save-minus",
        value: "i-linear-save-minus",
      },
      {
        label: "save-remove",
        value: "i-linear-save-remove",
      },
      {
        label: "scan",
        value: "i-linear-scan",
      },
      {
        label: "scan-barcode",
        value: "i-linear-scan-barcode",
      },
      {
        label: "scanner",
        value: "i-linear-scanner",
      },
      {
        label: "scanning",
        value: "i-linear-scanning",
      },
      {
        label: "scissor",
        value: "i-linear-scissor",
      },
      {
        label: "screenmirroring",
        value: "i-linear-screenmirroring",
      },
      {
        label: "scroll",
        value: "i-linear-scroll",
      },
      {
        label: "search-favorite",
        value: "i-linear-search-favorite",
      },
      {
        label: "search-normal",
        value: "i-linear-search-normal",
      },
      {
        label: "search-status",
        value: "i-linear-search-status",
      },
      {
        label: "search-zoom-in",
        value: "i-linear-search-zoom-in",
      },
      {
        label: "search-zoom-out",
        value: "i-linear-search-zoom-out",
      },
      {
        label: "security",
        value: "i-linear-security",
      },
      {
        label: "security-card",
        value: "i-linear-security-card",
      },
      {
        label: "security-safe",
        value: "i-linear-security-safe",
      },
      {
        label: "security-time",
        value: "i-linear-security-time",
      },
      {
        label: "security-user",
        value: "i-linear-security-user",
      },
      {
        label: "send",
        value: "i-linear-send",
      },
      {
        label: "send-square",
        value: "i-linear-send-square",
      },
      {
        label: "setting",
        value: "i-linear-setting",
      },
      {
        label: "settings",
        value: "i-linear-settings",
      },
      {
        label: "shapes",
        value: "i-linear-shapes",
      },
      {
        label: "share",
        value: "i-linear-share",
      },
      {
        label: "shield",
        value: "i-linear-shield",
      },
      {
        label: "shield-cross",
        value: "i-linear-shield-cross",
      },
      {
        label: "shield-search",
        value: "i-linear-shield-search",
      },
      {
        label: "shield-slash",
        value: "i-linear-shield-slash",
      },
      {
        label: "shield-tick",
        value: "i-linear-shield-tick",
      },
      {
        label: "ship",
        value: "i-linear-ship",
      },
      {
        label: "shop",
        value: "i-linear-shop",
      },
      {
        label: "shop-add",
        value: "i-linear-shop-add",
      },
      {
        label: "shopping-bag",
        value: "i-linear-shopping-bag",
      },
      {
        label: "shopping-cart",
        value: "i-linear-shopping-cart",
      },
      {
        label: "shop-remove",
        value: "i-linear-shop-remove",
      },
      {
        label: "sidebar-bottom",
        value: "i-linear-sidebar-bottom",
      },
      {
        label: "sidebar-left",
        value: "i-linear-sidebar-left",
      },
      {
        label: "sidebar-right",
        value: "i-linear-sidebar-right",
      },
      {
        label: "sidebar-top",
        value: "i-linear-sidebar-top",
      },
      {
        label: "signpost",
        value: "i-linear-signpost",
      },
      {
        label: "simcard",
        value: "i-linear-simcard",
      },
      {
        label: "size",
        value: "i-linear-size",
      },
      {
        label: "slash",
        value: "i-linear-slash",
      },
      {
        label: "slider",
        value: "i-linear-slider",
      },
      {
        label: "slider-horizontal",
        value: "i-linear-slider-horizontal",
      },
      {
        label: "slider-vertical",
        value: "i-linear-slider-vertical",
      },
      {
        label: "smallcaps",
        value: "i-linear-smallcaps",
      },
      {
        label: "smart-car",
        value: "i-linear-smart-car",
      },
      {
        label: "smart-home",
        value: "i-linear-smart-home",
      },
      {
        label: "smileys",
        value: "i-linear-smileys",
      },
      {
        label: "sms",
        value: "i-linear-sms",
      },
      {
        label: "sms-edit",
        value: "i-linear-sms-edit",
      },
      {
        label: "sms-notification",
        value: "i-linear-sms-notification",
      },
      {
        label: "sms-search",
        value: "i-linear-sms-search",
      },
      {
        label: "sms-star",
        value: "i-linear-sms-star",
      },
      {
        label: "sms-tracking",
        value: "i-linear-sms-tracking",
      },
      {
        label: "sort",
        value: "i-linear-sort",
      },
      {
        label: "sound",
        value: "i-linear-sound",
      },
      {
        label: "speaker",
        value: "i-linear-speaker",
      },
      {
        label: "speedometer",
        value: "i-linear-speedometer",
      },
      {
        label: "star",
        value: "i-linear-star",
      },
      {
        label: "star-slash",
        value: "i-linear-star-slash",
      },
      {
        label: "status",
        value: "i-linear-status",
      },
      {
        label: "status-up",
        value: "i-linear-status-up",
      },
      {
        label: "sticker",
        value: "i-linear-sticker",
      },
      {
        label: "stickynote",
        value: "i-linear-stickynote",
      },
      {
        label: "stop",
        value: "i-linear-stop",
      },
      {
        label: "stop-circle",
        value: "i-linear-stop-circle",
      },
      {
        label: "story",
        value: "i-linear-story",
      },
      {
        label: "strongbox",
        value: "i-linear-strongbox",
      },
      {
        label: "subtitle",
        value: "i-linear-subtitle",
      },
      {
        label: "sun",
        value: "i-linear-sun",
      },
      {
        label: "sun-fog",
        value: "i-linear-sun-fog",
      },
      {
        label: "tag",
        value: "i-linear-tag",
      },
      {
        label: "tag-cross",
        value: "i-linear-tag-cross",
      },
      {
        label: "tag-right",
        value: "i-linear-tag-right",
      },
      {
        label: "tag-user",
        value: "i-linear-tag-user",
      },
      {
        label: "task",
        value: "i-linear-task",
      },
      {
        label: "task-square",
        value: "i-linear-task-square",
      },
      {
        label: "teacher",
        value: "i-linear-teacher",
      },
      {
        label: "text",
        value: "i-linear-text",
      },
      {
        label: "textalign-center",
        value: "i-linear-textalign-center",
      },
      {
        label: "textalign-justifycenter",
        value: "i-linear-textalign-justifycenter",
      },
      {
        label: "textalign-justifyleft",
        value: "i-linear-textalign-justifyleft",
      },
      {
        label: "textalign-justifyright",
        value: "i-linear-textalign-justifyright",
      },
      {
        label: "textalign-left",
        value: "i-linear-textalign-left",
      },
      {
        label: "textalign-right",
        value: "i-linear-textalign-right",
      },
      {
        label: "text-block",
        value: "i-linear-text-block",
      },
      {
        label: "text-bold",
        value: "i-linear-text-bold",
      },
      {
        label: "text-italic",
        value: "i-linear-text-italic",
      },
      {
        label: "text-underline",
        value: "i-linear-text-underline",
      },
      {
        label: "tick-circle",
        value: "i-linear-tick-circle",
      },
      {
        label: "ticket",
        value: "i-linear-ticket",
      },
      {
        label: "ticket-discount",
        value: "i-linear-ticket-discount",
      },
      {
        label: "ticket-expired",
        value: "i-linear-ticket-expired",
      },
      {
        label: "ticket-star",
        value: "i-linear-ticket-star",
      },
      {
        label: "tick-square",
        value: "i-linear-tick-square",
      },
      {
        label: "timer",
        value: "i-linear-timer",
      },
      {
        label: "timer-pause",
        value: "i-linear-timer-pause",
      },
      {
        label: "timer-start",
        value: "i-linear-timer-start",
      },
      {
        label: "toggle-off",
        value: "i-linear-toggle-off",
      },
      {
        label: "toggle-off-circle",
        value: "i-linear-toggle-off-circle",
      },
      {
        label: "toggle-on",
        value: "i-linear-toggle-on",
      },
      {
        label: "toggle-on-circle",
        value: "i-linear-toggle-on-circle",
      },
      {
        label: "trade",
        value: "i-linear-trade",
      },
      {
        label: "transaction-minus",
        value: "i-linear-transaction-minus",
      },
      {
        label: "translate",
        value: "i-linear-translate",
      },
      {
        label: "trash",
        value: "i-linear-trash",
      },
      {
        label: "tree",
        value: "i-linear-tree",
      },
      {
        label: "trend-down",
        value: "i-linear-trend-down",
      },
      {
        label: "trend-up",
        value: "i-linear-trend-up",
      },
      {
        label: "triangle",
        value: "i-linear-triangle",
      },
      {
        label: "truck-remove",
        value: "i-linear-truck-remove",
      },
      {
        label: "truck-tick",
        value: "i-linear-truck-tick",
      },
      {
        label: "truck-time",
        value: "i-linear-truck-time",
      },
      {
        label: "trush-square",
        value: "i-linear-trush-square",
      },
      {
        label: "undo",
        value: "i-linear-undo",
      },
      {
        label: "unlimited",
        value: "i-linear-unlimited",
      },
      {
        label: "unlock",
        value: "i-linear-unlock",
      },
      {
        label: "user",
        value: "i-linear-user",
      },
      {
        label: "user-add",
        value: "i-linear-user-add",
      },
      {
        label: "user-cirlce-add",
        value: "i-linear-user-cirlce-add",
      },
      {
        label: "user-edit",
        value: "i-linear-user-edit",
      },
      {
        label: "user-minus",
        value: "i-linear-user-minus",
      },
      {
        label: "user-octagon",
        value: "i-linear-user-octagon",
      },
      {
        label: "user-remove",
        value: "i-linear-user-remove",
      },
      {
        label: "user-search",
        value: "i-linear-user-search",
      },
      {
        label: "user-square",
        value: "i-linear-user-square",
      },
      {
        label: "user-tag",
        value: "i-linear-user-tag",
      },
      {
        label: "user-tick",
        value: "i-linear-user-tick",
      },
      {
        label: "verify",
        value: "i-linear-verify",
      },
      {
        label: "video",
        value: "i-linear-video",
      },
      {
        label: "video-add",
        value: "i-linear-video-add",
      },
      {
        label: "video-circle",
        value: "i-linear-video-circle",
      },
      {
        label: "video-horizontal",
        value: "i-linear-video-horizontal",
      },
      {
        label: "video-octagon",
        value: "i-linear-video-octagon",
      },
      {
        label: "video-play",
        value: "i-linear-video-play",
      },
      {
        label: "video-remove",
        value: "i-linear-video-remove",
      },
      {
        label: "video-slash",
        value: "i-linear-video-slash",
      },
      {
        label: "video-square",
        value: "i-linear-video-square",
      },
      {
        label: "video-tick",
        value: "i-linear-video-tick",
      },
      {
        label: "video-time",
        value: "i-linear-video-time",
      },
      {
        label: "video-vertical",
        value: "i-linear-video-vertical",
      },
      {
        label: "voice-cricle",
        value: "i-linear-voice-cricle",
      },
      {
        label: "voice-square",
        value: "i-linear-voice-square",
      },
      {
        label: "volume-cross",
        value: "i-linear-volume-cross",
      },
      {
        label: "volume-high",
        value: "i-linear-volume-high",
      },
      {
        label: "volume-low",
        value: "i-linear-volume-low",
      },
      {
        label: "volume-mute",
        value: "i-linear-volume-mute",
      },
      {
        label: "volume-slash",
        value: "i-linear-volume-slash",
      },
      {
        label: "volume-up",
        value: "i-linear-volume-up",
      },
      {
        label: "wallet",
        value: "i-linear-wallet",
      },
      {
        label: "wallet-add",
        value: "i-linear-wallet-add",
      },
      {
        label: "wallet-check",
        value: "i-linear-wallet-check",
      },
      {
        label: "wallet-minus",
        value: "i-linear-wallet-minus",
      },
      {
        label: "wallet-money",
        value: "i-linear-wallet-money",
      },
      {
        label: "wallet-remove",
        value: "i-linear-wallet-remove",
      },
      {
        label: "wallet-search",
        value: "i-linear-wallet-search",
      },
      {
        label: "watch",
        value: "i-linear-watch",
      },
      {
        label: "watch-status",
        value: "i-linear-watch-status",
      },
      {
        label: "weight",
        value: "i-linear-weight",
      },
      {
        label: "wifi",
        value: "i-linear-wifi",
      },
      {
        label: "wifi-square",
        value: "i-linear-wifi-square",
      },
      {
        label: "wind",
        value: "i-linear-wind",
      },
      {
        label: "woman",
        value: "i-linear-woman",
      },
    ],
  }),

  actions: {},

  getters: {},
});
