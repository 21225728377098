import { createApp } from "vue";
import { createPinia } from "pinia";
import { createVfm } from "vue-final-modal";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { plugin, defaultConfig } from "@formkit/vue";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import Popper from "vue3-popper";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import PrimeVue from 'primevue/config';
import VueSidePanel from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import 'vue-global-api';
import App from "./App.vue";
import { useAppStore } from "./stores/app";
import {
  createWebHistory,
  createRouter,
  setupDataFetchingGuard,
} from "vue-router/auto";
// import { router } from "./router/index.ts";
// import { useQueryClient, useQuery, useMutation } from 'tanstack/vue-query';
// import { queries, mutations } from './queries/index';
import config from "./formkit.config.js";
import "./styles/main.css";
import "uno.css";
import "./styles/forms.scss";
// import "css-aspect-ratio/css-aspect-ratio.css";
import "./assets/iconsax-css/style.css";
import "./assets/iconsax-css/fonts/iconsax.svg";
import "vue-final-modal/style.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/soho-light/theme.css";
import ConfirmationService from 'primevue/confirmationservice';
import { VueQueryPlugin } from "@tanstack/vue-query";
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VirtualCollection from 'vue-virtual-collection';
import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';



const app = createApp(App);
const pinia = createPinia();
const vfm = createVfm();

const router = createRouter({
  history: createWebHistory(),
  extendRoutes: (routes) => {
    // routes.find((r) => r.name === '/')!.meta = {}
    return routes
  },
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from) => {
  // if (to.meta.requiresAuth === true) {
    // console.log('beforeEach', to, from)
  //   const appStore = useAppStore();
  //   if (appStore.userData === null) {
  //     console.log(appStore.userData)
  //   }
  // }
  return true
})

setupDataFetchingGuard(router);

const toastOptions = {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 20,
  newestOnTop: true,
};

app.use(VueQueryPlugin);
app.use(pinia);
app.use(VueVirtualScroller)
app.use(vfm);
app.use(router);
app.use(VirtualCollection)
app.use(autoAnimatePlugin);
app.use(plugin, defaultConfig(config));
app.use(PerfectScrollbar);
app.use(Toast, toastOptions);
app.use(PrimeVue);
app.use(Popper);
app.use(ConfirmationService);
app.use(VueSidePanel);

app.mount("#app");