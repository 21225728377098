<script setup lang="ts">
import { useManagerStore } from '@/stores/manager';
import { useRouter, useRoute } from 'vue-router/auto';
import { computed, ref, watch, onMounted, provide } from 'vue';
import { definePage } from 'vue-router/auto'

definePage({
  meta: {
    requiresAuth: true,
    requiresStaff: true,
  },
})

const managerStore = useManagerStore();
const router = useRouter();
const route = useRoute();
const params = route.params as {studio: string, page: string};
const studio = params.studio;

const routeSplit = computed(() => {
    const split = route.path.split('/');
    return split;
})

const workspacesList = managerStore.workspaces;
const activeWorkspace = computed(() => managerStore.activeWorkspace);
const activeView = computed(() => managerStore.activeView);

const sidePanelTabs = computed(() => {
    const workspace = managerStore.workspaces.find((workspace) => workspace.id === activeWorkspace.value);
    return workspace.views;
});

function setActive(tab) {
    managerStore.setActiveWorkspace(tab.id);
    managerStore.setActiveView(0);
    router.push('/' + studio + tab.routerLink);
}

function setActiveView(tab) {
    managerStore.setActiveView(tab.id);
    const workspace = managerStore.workspaces.find((workspace) => workspace.id === activeWorkspace.value);
    router.push('/' + studio + workspace.routerLink + tab.routerLink);
}

function closeSidePanel() {
    managerStore.sidePanelOpen = false;
}

watch(() => managerStore.sidePanelOpen, (newVal) => {
    if (newVal == false) {
        setTimeout(() => {
            managerStore.sidePanelProps = {
                width: "800px",
            }
            managerStore.sidePanelComponent = {
            title: null,
            component: null,
            icon: null,
            showHeader: true,
        }
        }, 300);
    }
})

const manageOuterWrapper = ref(null);

provide('scrollbar', manageOuterWrapper);

onMounted(() => {
    // console.log(routeSplit.value[3]);
    // console.log(routeSplit.value[4]);
    if (routeSplit.value[3] == 'training') {
        managerStore.setActiveWorkspace(4);
        if (routeSplit.value[4] == undefined) { // dashboard
            managerStore.setActiveView(0);
        } else if (routeSplit.value[4] == 'coaching') {
            managerStore.setActiveView(1);
        } else if (routeSplit.value[4] == 'programs') {
            managerStore.setActiveView(2);
        } else if (routeSplit.value[4] == 'workouts') {
            managerStore.setActiveView(3);
        } else if (routeSplit.value[4] == 'flows') {
            managerStore.setActiveView(4);
        } else if (routeSplit.value[4] == 'exercises') {
            managerStore.setActiveView(5);
        } else if (routeSplit.value[4] == 'levels') {
            managerStore.setActiveView(6);
        }
        else if (routeSplit.value[4] == 'grading') {
            managerStore.setActiveView(7);
        }
        else if (routeSplit.value[4] == 'skilltree') {
            managerStore.setActiveView(8);
        }
    }
})

const innerWrapper = ref(null);
const routerViewHeight = computed(() => innerWrapper.value?.clientHeight);

provide('routerViewHeight', routerViewHeight);

</script>

<template>
<!-- <PerfectScrollbar ref="scrollbar" @ps-scroll-y="onScroll"> -->
    <div class="outer-wrapper" ref="manageOuterWrapper">
        <base-menu-bar :tabs="sidePanelTabs" :activeTab="activeView" @menuclicked="(tab) => setActiveView(tab)"></base-menu-bar>
        <div class="inner-wrapper" ref="innerWrapper">
            <base-menu-top-bar :tabs="workspacesList" :activeTab="activeWorkspace" @activeTab="(tab) => setActive(tab)" ></base-menu-top-bar>
            <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                        <component :is="Component" />
                </transition>
            </router-view>
        </div>
        <VueSidePanel
            v-model="managerStore.sidePanelOpen"
            lock-scroll
            v-bind="managerStore.sidePanelProps"
            overlay-color="rgba(0,0,0,0.5)"
            hide-close-btn
            header-class="sidepanel-header"
            panel-color="var(--grey-100)"
            rerender
        >
            <template #header v-if="managerStore.sidePanelComponent.showHeader != false">
                <div class="sidepanel-header">
                    <div class="header-inner">
                        <div class="title-group">
                            <div class="icon" :class="managerStore.sidePanelComponent.icon"></div>
                            <div class="title">{{ managerStore.sidePanelComponent.title }}</div>
                        </div>
                        <div class="icon i-linear-close-square" @click="closeSidePanel" v-if="managerStore.sidePanelProps?.noClose !== true"></div>
                    </div>
                </div>
            </template>
            <template #default>
                <div class="sidepanel-component-container">
                    <!-- <ManageSidepanelDemo /> -->
                    <component :is="managerStore.sidePanelComponent.component" v-bind="managerStore.sidePanelComponent.props"/>
                </div>
            </template>
        </VueSidePanel>

    </div>
<!-- </PerfectScrollbar> -->
</template>

<style scoped lang="scss">
.ps {
  display: block;
  height: 100%;
  width: 100%;
  overflow: visible;
  position: relative;
  background-color: var(--grey-100);
}
.title-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
    .icon {
        font-size: 1.5rem;
        color: var(--color-text) !important;
        cursor: unset !important;
    }
    .title {
            font: var(--h5);
            color: var(--color-text);
        }
}
.sidepanel-component-container {
    width: 100%;
    height: 100%;
}
.sidepanel-header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text);
    padding: var(--pad);
    overflow: visible !important;
    .header-inner {
        background-color: var(--bg);
        box-shadow: var(--shadow);
        z-index: 10;
        height: 70px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: var(--pad);
        border-radius: var(--radius-lg);
        .icon {
            font-size: 1.5rem;
            color: var(--color-text-light);
            cursor: pointer;
            transition: var(--transition-fast);
            &:hover {
                color: var(--primary);
            }
        }
    }
}
.outer-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    min-height: 100vh;
    height: fit-content;
    gap: var(--gap);
    overflow-x: hidden;
    overflow-y: scroll;
}
.outer-wrapper::-webkit-scrollbar {
    width: 1rem;
    background: transparent;
}
.outer-wrapper::-webkit-scrollbar-track {
    // background: hsla(260, 99%, 50%, 0.1);
    background: transparent;
    border-radius: var(--radius);
    border: 4px solid var(--grey-light);
}
.outer-wrapper::-webkit-scrollbar-thumb {
    background: var(--grey);
    border-radius: var(--radius);
    border: 4px solid transparent;
    background-clip: content-box;
    transition: var(--transition);
    cursor: grab;
    &:hover {
        background: hsla(260, 99%, 50%, 0.5);
        border-radius: var(--radius);
        border: 4px solid transparent;
        background-clip: content-box;
        transition: var(--transition-fast);
        cursor: grab;
    }
}
.inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    gap: var(--gap);
    padding: var(--pad) var(--pad) var(--pad) 8rem;
}
.vsp__body {
    /* width */
    ::-webkit-scrollbar {
    width: 10px;
    display: none;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    display: none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    display: none;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    display: none;
    }
}
</style>