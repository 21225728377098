<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useIconStore } from "@/stores/icon";

const iconStore = useIconStore()

const props = defineProps({
  context: Object,
})

function handleInput(e) {
  props.context.node.input(e.target.value)
  inputValue.value = e.target.value
}

const focused = ref(false)
const hovered = ref(false)
const resultIcon = ref(null)
const inputValue = ref('')

const bgVisible = computed(() => {
  return focused.value || hovered.value
})

const popularVisible = computed(() => {
  if (iconList.value.length == 1) {
    return true
  } else {
    return !props.context._value
  }
})

const placeholderVisible = computed(() => {
  if (props.context._value) {
    return false
  }
  else {
    return !focused.value
  }
})

const iconList = computed(() => {
  const icons = iconStore.icons
  if (inputValue.value) {
    return icons.filter((icon) => {
      return icon.label.includes(inputValue.value)
    })
    }
    else {
      return icons
    }
})

function clickIcon(selected) {
  props.context.node.input(selected.value)
  resultIcon.value = selected
  inputValue.value = selected.label
}

const resultVisible = computed(() => {
  if (props.context._value && resultIcon.value) {
    if (focused.value) {
      return false
    }
    else {
      return true
    }
  }
  else {
    return false
  }
})

onMounted(() => {
  if (props.context._value) {
    const selectedIcon = iconList.value.filter((icon) => {
      return icon.value == props.context._value
    })[0]
    resultIcon.value = selectedIcon;
    inputValue.value = selectedIcon.label
  }
})
</script>

<template>
<div class="input-wrapper" @mouseenter="hovered = true" @mouseleave="hovered = false" >
    <div class="search-wrapper">
        <input class="formkit-input search-bar" :class="{'hide': resultVisible}" @focus="focused = true" @blur="focused = false" type="text" @input="handleInput" :value="inputValue" />
        <TransitionGroup name="placeholder">
            <div class="placeholder" v-if="placeholderVisible">
                <div class="i-linear-search-normal icon"></div>
                <div>Search</div>
            </div>
            <div class="result" v-if="resultVisible">
                <div class="icon" :class="resultIcon.value"></div>
                <p>{{ resultIcon.label }}</p>
            </div>
        </TransitionGroup>
    </div>
    <TransitionGroup name="picker">
    <div class="background" v-if="bgVisible" v-auto-animate>
        <div class="title-group">
            <p>Icon Picker</p>
            <div class="i-linear-search-normal"></div>
        </div>
        <div class="spacer" v-if="popularVisible">
            <div class="subtitle" >Popular</div>
            <div class="popular">
                <div class="icon" v-for="icon in iconStore.popular.slice(0, 20)" :key="icon" @click="clickIcon(icon)">
                    <div :class="icon.value"></div>
                </div>
            </div>
        </div>
        <div class="spacer" v-else>
            <div class="subtitle" >Search Results</div>
            <div class="popular">
                <div class="icon" v-for="icon in iconList.slice(0, 30)" :key="icon" @click="clickIcon(icon)">
                    <div :class="icon.value"></div>
                </div>
                <div v-if="iconList.length == 0" class="error">
                    <p>Womp, Womp...try again? </p>
                </div>
            </div>
        </div>
    </div>
    </TransitionGroup>
</div>
</template>

<style scoped lang="scss">
.input-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}
.placeholder {
    position: absolute;
    z-index: 200;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 1rem;
    color: var(--grey);
    .icon {
        font-size: 1.5rem;
        color: var(--grey);
    }
}
.result {
    position: absolute;
    z-index: 200;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 1rem;
    color: var(--dark-grey);
    .icon {
        font-size: 1.5rem;
        color: var(--dark-grey);
    }
}
.search-bar {
    display: flex; 
    width: 100%;
    z-index: 100;
    transition-duration: 500ms !important;

    &:focus {
        outline: none;
        box-shadow: var(--fk-border-box-shadow-focus);
    }
    &.hide {
        color: transparent;
        transition-duration: 0ms !important;
    }
}
.search-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.subtitle{
    font: var(--h6);
    color: var(--grey);
    line-height: 2.5rem;
}
.spacer {
    padding-top: 5rem;
}
.popular {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 0.75rem;
    width: 100%;
    height: 100%;
    padding-top: 0;
    .icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        width: 2rem;
        height: 2rem;
        border-radius: var(--radius);
        color: var(--grey);
        background-color: var(--bg);
        box-shadow: 0px 0px 1rem -0.25rem rgba(0, 0, 0, 0.5);
        cursor: pointer;
        transition: var(--transition-fast);
        &:hover {
            color: var(--primary);
            transform: scale(1.1);
        }
    }
}
.background {
    display: flex;
    flex-direction: column;
    z-index: 90;
    position: absolute;
    top: -3rem;
    left: -1rem;
    padding: 1rem;
    width: calc(100% + 2rem);
    min-width: min-content;
    min-height: min-content;
    background-color: var(--bg);
    border-radius: var(--radius-xl);
    box-shadow: var(--shadow);
}
.title-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 1rem;
    p {
        font: var(--h6);
        color: var(--grey);
        line-height: 1.5rem;
    }
    .i-linear-search-normal {
        font-size: 1.5rem;
        color: var(--grey);
    }
}
.error {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    p {
        font: var(--text);
        color: var(--grey);
        line-height: 1.5rem;
        white-space: nowrap;
    }
}

.picker-enter-active {
  transition: var(--transition-fast);
}
.picker-leave-active {
  transition: var(--transition-fast);
}
.picker-enter-from,
.picker-leave-to {
  opacity: 0;
}

.placeholder-enter-active {
  transition: var(--transition);
}
.placeholder-leave-active {
  transition: var(--transition);
}
.placeholder-enter-from,
.placeholder-leave-to {
  opacity: 0;
  transform: translateX(-0.5rem) scale(0.8);
}
</style>